import { OrderEnum, useUserAnswerHistoryQuery } from '@generated/graphql';
import { useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import { Box, Card, Chip, Stack, Typography } from '@mui/material';
import { createUserName } from 'helpers/common';
import { HOMEWORK_STATUS_COLOR, HOMEWORK_STATUS_NAME } from 'constants/global';
import { formatISODate } from 'helpers/date';
import { Person } from '@mui/icons-material';

const History = () => {
  const { userAnswerId } = useParams();

  const { data, loading } = useUserAnswerHistoryQuery({
    variables: { answerId: userAnswerId!, order: OrderEnum.Desc },
    fetchPolicy: 'network-only',
  });

  const userAnswerHistory = data?.userAnswerHistory;

  if (loading) return <CircularLoading />;

  return (
    <Stack spacing={2}>
      {userAnswerHistory?.length ? (
        userAnswerHistory?.map((history) => (
          <Card sx={{ maxWidth: 800 }}>
            <Stack direction='row' alignItems='center'>
              <Person sx={{ mr: 2 }} />
              <Typography variant='text6'>{createUserName(history.user)}</Typography>
              <Box flex={1} />
              {history.newStatus && (
                <Chip
                  size='small'
                  label={HOMEWORK_STATUS_NAME[history.newStatus]}
                  sx={{ backgroundColor: HOMEWORK_STATUS_COLOR[history.newStatus], mr: 2 }}
                />
              )}
              <Typography variant='text6'>{formatISODate(history.createdAt, 'dd.MM.yyyy HH:mm')}</Typography>
            </Stack>
          </Card>
        ))
      ) : (
        <Typography>Нет истории изменения статуса домашнего задания</Typography>
      )}
    </Stack>
  );
};
export default History;
