import React, { Dispatch, SetStateAction } from 'react';
import { Stack, Typography } from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { useDropzone } from 'react-dropzone';
import { DroppedFile } from 'components/inputs/control/Dropzone';

type ImageUploaderProps = {
  text?: string;
  disabled?: boolean;
  height?: number;
  width?: number;
  setOpenCropModal: Dispatch<SetStateAction<boolean>>;
  setFile: Dispatch<SetStateAction<DroppedFile>>;
};

const ImageUploader: React.FC<ImageUploaderProps> = ({ text, disabled, height, width, setOpenCropModal, setFile }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setFile({ base64: reader.result, file: acceptedFiles[0] });
      });
      reader.readAsDataURL(acceptedFiles[0]);
      setOpenCropModal(true);
    },
    accept: {
      'image/*': [],
    },
    useFsAccessApi: !disabled,
  });
  return (
    <div {...getRootProps({})} style={{ height: height || '100%' }}>
      <Stack
        alignItems='center'
        justifyContent='center'
        sx={{
          cursor: disabled ? undefined : 'pointer',
          height: height || 100,
          width: width || '100%',
          bgcolor: 'base.200',
          borderRadius: 2,
        }}
      >
        <input {...getInputProps()} accept='image/*' disabled={disabled} />
        <DriveFolderUploadIcon />
        <Typography whiteSpace='pre-wrap'>{`Загрузить ${text || 'обложку'}`}</Typography>
      </Stack>
    </div>
  );
};

export default ImageUploader;
