type GeneratedOptionsReturn = { id: string; displayName: string }[] | [];

const generateOptions = (object: object): GeneratedOptionsReturn =>
  Object.entries(object).reduce((acc: GeneratedOptionsReturn, current: [string, string]) => {
    const newValue: { id: string; displayName: string } = {
      displayName: current[1],
      id: current[0],
    };
    // @ts-ignore
    acc.push(newValue);
    return acc;
  }, []);

export default generateOptions;
