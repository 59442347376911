import { useNavigate, useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import {
  ElementFileType,
  ElementType,
  QuestionScoreType,
  QuestionType,
  useGetExerciseElementsQuery,
  useUpdateElementsMutation,
} from '@generated/graphql';
import { useFieldArray, UseFieldArrayReturn, useForm } from 'react-hook-form';
import React, { useContext, useMemo, useState } from 'react';
import { Form } from 'components/inputs';
import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import BackButton from 'components/buttons/BackButton';
import ROUTES from 'constants/routes';
import { EXERCISE_TYPE_NAME } from 'constants/global';
import { ListAltOutlined } from '@mui/icons-material';
import { ELEMENT_OPTIONS } from 'constants/course';
import { ExerciseForm, FilePair, IFile } from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/types';
import { getFileName } from 'helpers/common';
import CourseNavigation from './CourseNavigation';
import Element from './Element';
import onSaveExercise from './helpers/onSaveExercise';

const ExerciseConstructor = () => {
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const params = useParams<{ courseId: string; exerciseId: string }>();
  const exerciseId = params.exerciseId as string;
  const courseId = params.courseId as string;

  const [openNavigation, setOpenNavigation] = useState<boolean>(false);

  const { data, loading, error } = useGetExerciseElementsQuery({ variables: { exerciseId } });
  const exercise = data?.exercise;

  const form = useForm<ExerciseForm>({
    values: {
      ...exercise,
      elements: exercise?.elements?.map((elem: any) => ({
        ...elem,
        filePairs:
          elem.type === ElementType.File
            ? elem.files?.reduce((acc: FilePair[], currentFile: IFile, index: number, array: IFile[]) => {
                if (currentFile.type === ElementFileType.Cover) {
                  const cover = currentFile;
                  const download = array[index + 1]; // следующий элемент — файл для скачивания
                  acc.push({
                    cover,
                    download: {
                      ...download,
                      fileNameWithoutExtension: getFileName(download.fileName, 'name'),
                    },
                  });
                }
                return acc;
              }, [])
            : undefined,
        open: false,
        type: ELEMENT_OPTIONS.find((option: any) => {
          const isQuestion = elem.type === ElementType.Question;
          const elementType = isQuestion ? `${elem.type}_${elem.questionType}` : elem.type;
          return option.id === elementType;
        }),
        ...(elem.type === ElementType.Question && {
          files: elem.files?.map((file: IFile) => ({
            ...file,
            fileNameWithoutExtension: getFileName(file.fileName, 'name'),
          })),
        }),
        ...(elem.questionType === QuestionType.SingleAnswer && {
          singleCorrectAnswer: elem.elementParts.findIndex((part: any) => part.isCorrect).toString(),
        }),
      })),
    },
  });
  const { control, handleSubmit, watch } = form;

  const fieldArray = useFieldArray({
    control,
    keyName: 'formId',
    name: 'elements',
  }) as unknown as UseFieldArrayReturn;

  const { fields, append } = fieldArray;

  const appendElement = () => {
    append({ type: '', open: true, scoreType: QuestionScoreType.Full, id: null, files: null, filePairs: null });
  };

  const [updateElements, { loading: updateElementsLoading }] = useUpdateElementsMutation();

  const onSubmit = (formData: any) => {
    updateElements({
      variables: {
        input: {
          elements: onSaveExercise(formData),
          exerciseId,
        },
      },
    })
      .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const onError = () => {
    addToast({ type: ToastTypeEnum.ERROR });
  };

  const elements = watch('elements');

  const isUploadFileElementExist = useMemo(
    () =>
      !!elements?.find(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (element: any) =>
          element?.questionType?.id === QuestionType.FileAnswer || element?.type?.id === 'QUESTION_FILE_ANSWER',
      ),
    [elements],
  );

  const lesson = exercise?.lesson;
  const block = lesson?.block;
  const course = block?.course;

  const header = `Курс: ${course?.title}, Блок: ${block?.title}, Урок: ${lesson?.title}`;

  if (loading) return <CircularLoading />;
  if (error || !exercise) return <Typography>Часть урока не найдена</Typography>;

  return (
    <>
      <CourseNavigation open={openNavigation} setOpen={setOpenNavigation} currentBlock={block} />
      <Stack spacing={2} sx={{ pb: 3 }}>
        <BackButton navigate={() => navigate(`/${ROUTES.COURSES}/${courseId}`)} text='Назад к содержанию' />
        <Stack direction='row' spacing={2} alignItems='flex-start'>
          <Tooltip title='Навигация по курсу'>
            <IconButton
              onClick={() => setOpenNavigation(!openNavigation)}
              sx={{ color: 'base.400', width: 40, height: 40 }}
            >
              <ListAltOutlined />
            </IconButton>
          </Tooltip>
          <Box>
            <Typography variant='h4'>{`${EXERCISE_TYPE_NAME[exercise.type]}: ${exercise.title}`}</Typography>
            <Typography variant='text7' sx={{ color: 'base.400' }}>
              {header}
            </Typography>
          </Box>
        </Stack>
        <Form form={form} onSubmit={handleSubmit(onSubmit, onError)}>
          <Stack spacing={3}>
            {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
            {fields.map((element: any, index) => (
              <Element
                form={form}
                elementIndex={index}
                element={element}
                fieldArray={fieldArray}
                key={`element-${element.formId}`}
                isUploadFileElementExist={isUploadFileElementExist}
              />
            ))}
            <Button startIcon={<AddIcon />} sx={{ width: 'fit-content' }} onClick={() => appendElement()}>
              элемент
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              color='primary'
              loading={updateElementsLoading}
              loadingIndicator='Загрузка'
              sx={{ width: 200, position: 'fixed', bottom: 16, right: 24, zIndex: 999 }}
            >
              Сохранить
            </LoadingButton>
          </Stack>
        </Form>
      </Stack>
    </>
  );
};

export default ExerciseConstructor;
