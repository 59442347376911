import { InputTypes } from 'components/generators/inputsGenerator';
import { FILTER_TYPES } from 'components/generators/filtersGenerator';

const filterFields = () => [
  {
    name: 'userNumericId',
    label: 'ID пользователя',
    inputType: InputTypes.TEXT,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    customFilter: (value: string) => parseFloat(value),
  },
  {
    name: 'userFullName',
    label: 'ФИО',
    inputType: InputTypes.TEXT,
  },
  {
    name: 'coursePurchaseAt',
    label: 'Дата получения курса',
    inputType: InputTypes.DATE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    customFilter: (value: string) => value,
  },
];

export default filterFields;
