import React, { FC } from 'react';
import { Button, Stack } from '@mui/material';
import TextFieldControl from 'components/inputs/control/TextFieldControl';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import ActionButton, { ActionButtonEnum } from 'components/buttons/ActionButton';
import { ExerciseForm } from '../../types';

export interface IMatchingProps {
  form: UseFormReturn<ExerciseForm>;
  elementIndex: number;
}

const Matching: FC<IMatchingProps> = ({ form, elementIndex }) => {
  const { control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    keyName: 'formId',
    name: `elements.${elementIndex}.elementParts`,
  });

  const appendSelect = () => {
    append({ content: '', correctAnswer: '' });
  };

  return (
    <Stack sx={{ mt: 3, width: '100%' }} spacing={2}>
      {fields.map((element, index) => (
        <Stack direction='row' spacing={2} alignItems='center' key={`match-fields-${element.formId}`}>
          <TextFieldControl
            placeholder='Термин'
            name={`elements.[${elementIndex}].elementParts.[${index}].content`}
            rules={{ required: true }}
            multiline
          />
          <TextFieldControl
            placeholder='Соответствие'
            name={`elements.[${elementIndex}].elementParts.[${index}].correctAnswer`}
            rules={{ required: true }}
            multiline
          />
          <ActionButton
            sx={{ ml: '24px !important' }}
            handleClick={() => remove(index)}
            type={ActionButtonEnum.DELETE}
          />
        </Stack>
      ))}
      <Button sx={{ mt: 3, width: 'fit-content' }} startIcon={<AddIcon />} onClick={appendSelect}>
        Добавить соответствие
      </Button>
    </Stack>
  );
};

export default Matching;
