import CircularLoading from 'components/CircularLoading';
import { usePaymentQuery } from '@generated/graphql';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import CoursePayment from 'pages/Course/view/tabs/Payment/components/CoursePayment';
import BlocksPayment from 'pages/Course/view/tabs/Payment/components/BlocksPayment';

const Payment = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const { data, loading, error } = usePaymentQuery({
    variables: {
      courseId: courseId!,
      filter: {
        courseId: courseId!,
      },
      limit: 1000,
      page: 0,
    },
  });

  const course = data?.course;
  const blocks = data?.blocks?.data;

  if (loading || !!error) return <CircularLoading />;

  return (
    <Stack spacing={6}>
      <CoursePayment course={course} />
      <BlocksPayment blocks={blocks} />
    </Stack>
  );
};

export default Payment;
