import {
  ElementFileType,
  ElementInput,
  ElementPartType,
  ElementType,
  QuestionScoreType,
  QuestionType,
} from '@generated/graphql';
import {
  ElementPart,
  ExerciseForm,
  FilePair,
  IFile,
  IQuestionElement,
  IQuestionFullScore,
  IQuestionMultipleAnswer,
  IQuestionPartialScore,
  IQuestionSingleAnswer,
  IVideoElement,
} from '../types';

// ---------------VIDEO ELEMENT

const prepareVideoElement = (element: IVideoElement) => {
  const inputValue = element.kinescopeId;
  const onlyId = inputValue.replace('https://kinescope.io/', '');
  return {
    videoElement: {
      kinescopeId: onlyId,
    },
  };
};

// ---------------FILE ELEMENT

const getFileName = (file: IFile) => {
  const defaultFileName = file.file?.name as string;
  const fileExtension = defaultFileName.substring(defaultFileName.lastIndexOf('.'), defaultFileName.length);
  const newFileName = file.fileNameWithoutExtension || file.fileName;

  if (newFileName) {
    if (newFileName.includes('.')) {
      return newFileName.substring(0, newFileName.lastIndexOf('.')) + fileExtension;
    }
    return newFileName + fileExtension;
  }
  return file.file?.name;
};

const preparedFileElement = (restElementProps: { filePairs: FilePair[] }) => ({
  fileElement: {
    files: restElementProps.filePairs?.reduce((acc: IFile[], currentPair: FilePair) => {
      const { cover, download } = currentPair;
      acc.push(
        {
          id: cover.id,
          type: ElementFileType.Cover,
          file: !cover.file?.url ? cover.file : undefined,
          fileName: getFileName(cover),
        },
        {
          id: download.id,
          type: ElementFileType.Download,
          file: !download.file?.url ? download.file : undefined,
          fileName: getFileName(download),
        },
      );

      return acc;
    }, []),
  },
});

// ---------------QUESTION ELEMENT

const preparedQuestionElement = (element: IQuestionElement, questionType: QuestionType) => {
  const { elementParts } = element;

  const isSingleAnswerQuestion = (
    el: IQuestionElement,
  ): el is IQuestionSingleAnswer & (IQuestionFullScore | IQuestionPartialScore) =>
    questionType === QuestionType.SingleAnswer;

  const isFullScoreQuestion = (
    el: IQuestionElement,
  ): el is IQuestionFullScore & (IQuestionSingleAnswer | IQuestionMultipleAnswer) =>
    el.scoreType === QuestionScoreType.Full;

  const isPartialScoreQuestion = (
    el: IQuestionElement,
  ): el is IQuestionPartialScore & (IQuestionSingleAnswer | IQuestionMultipleAnswer) =>
    el.scoreType === QuestionScoreType.Partial;

  return {
    questionElement: {
      description: element.description,
      title: element.title,
      placeholder: element.placeholder,
      maxScore: isFullScoreQuestion(element) ? Number.parseFloat(element.maxScore) : undefined,
      ...(isPartialScoreQuestion(element) && {
        positiveScore: Number.parseFloat(element.positiveScore),
        negativeScore: Number.parseFloat(element.negativeScore),
      }),
      scoreType: element.scoreType,
      files: element?.files?.length
        ? element.files.map((file: IFile) => {
            const currentFile = file?.file || file;
            const isOldFile = 'url' in currentFile && !!currentFile?.url;
            const fileObj = isOldFile ? undefined : currentFile;
            return {
              fileName: getFileName(file),
              file: fileObj,
              type: ElementFileType.Content,
              id: file.id,
            };
          })
        : null,
      questionType,
      answers: elementParts?.map((part: ElementPart, index: number) => ({
        content: part.content,
        correctAnswer: part.correctAnswer,
        id: part.id,
        isCorrect: isSingleAnswerQuestion(element)
          ? index === parseInt(element.singleCorrectAnswer, 10)
          : part.isCorrect,
        type: questionType === QuestionType.Matching ? ElementPartType.Matching : ElementPartType.Answer,
      })),
    },
  };
};

const onSaveExercise = (formData: ExerciseForm) => {
  const { elements } = formData;

  return elements?.map((element: any) => {
    const { id, type: inputType } = element;
    const elementType = inputType?.id || inputType;
    const isQuestion = inputType?.id ? elementType.includes(ElementType.Question) : inputType === ElementType.Question;
    const [type, questionType] =
      inputType?.id && isQuestion ? elementType.split(/_(.*)/s) : [elementType, element.questionType];

    const isFile = elementType === ElementType.File;
    const isHeading = elementType === ElementType.Heading;
    const isText = elementType === ElementType.Text;
    const isVideo = elementType === ElementType.Video;
    const isExerciseLink = elementType === ElementType.ExerciseLink;

    return {
      id,
      type,
      ...(isFile ? preparedFileElement(element) : undefined),
      ...(isHeading ? { headingElement: { title: element.title } } : undefined),
      ...(isQuestion ? preparedQuestionElement(element, questionType) : undefined),
      ...(isText ? { textElement: { content: element.content } } : undefined),
      ...(isExerciseLink ? { exerciseLinkElement: { description: element.description } } : undefined),
      ...(isVideo ? prepareVideoElement(element) : undefined),
    };
  }) as Array<ElementInput>;
};

export default onSaveExercise;
