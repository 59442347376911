import { RefObject } from 'react';
import { FilePreview } from 'types/common';
import { DroppedFile } from 'components/inputs/control/Dropzone/index';

type OnCreateCroppedPreviewProps = {
  completedCrop: any;
  previewCanvasRef: RefObject<any>;
  imgRef: RefObject<any>;
  uploadNewImage: (newFile: FilePreview) => void;
  droppedFile: DroppedFile;
};

const onCreateCroppedPreview = ({
  completedCrop,
  previewCanvasRef,
  imgRef,
  uploadNewImage,
  droppedFile,
}: OnCreateCroppedPreviewProps) => {
  if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
    return;
  }
  // отрисовываем обрезанное на канвасе
  const image = imgRef.current;
  const canvas = previewCanvasRef.current;
  const crop = completedCrop;

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');
  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY,
  );
  canvas.toBlob(
    (blob: Blob | null) => {
      if (blob) {
        const file = new File([blob], droppedFile?.file.name || 'name');
        const fileWithPreview: FilePreview = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        uploadNewImage(fileWithPreview);
      }
    },
    'image/png',
    1,
  );
};

export default onCreateCroppedPreview;
