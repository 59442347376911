import React, { createContext, ReactNode, useMemo, useState } from 'react';
import Modal from 'components/Modal';
import { Breakpoint } from '@mui/material';

type ModalProps = {
  title: string;
  content: string | React.JSX.Element;
  loading?: boolean;
  handleClick: () => any;
  buttonText?: string;
  maxWidth?: Breakpoint | false;
};

interface ModalContextType {
  handleOpen: (modalProps: ModalProps) => void;
}

export const ModalContext = createContext<ModalContextType>({
  handleOpen: () => {},
});

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalProps>({
    title: '',
    content: '',
    loading: false,
    handleClick: async () => {},
  });

  const handleOpen = ({ title, content, handleClick, ...rest }: ModalProps) => {
    setModalProps({ title, content, handleClick, ...rest });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { handleClick } = modalProps;

  const onSubmit = async () => {
    try {
      setModalProps({ ...modalProps, loading: true });
      await handleClick();
      setModalProps({ ...modalProps, loading: false });
      handleClose();
    } catch (e) {
      setModalProps({ ...modalProps, loading: false });
    }
  };

  const providerValue = useMemo(() => ({ handleOpen }), [handleOpen]);

  return (
    <ModalContext.Provider value={providerValue}>
      <Modal open={open} onClose={handleClose} {...{ ...modalProps, handleClick: onSubmit }} />
      {children}
    </ModalContext.Provider>
  );
};
