import { useParams } from 'react-router-dom';
import { BlockAvailabilityType, useBlocksQuery, useUpdateBlocksMutation } from '@generated/graphql';
import CircularLoading from 'components/CircularLoading';
import { Button, Stack } from '@mui/material';
import { useFieldArray, UseFieldArrayReturn, useForm } from 'react-hook-form';
import { useCallback, useContext, useMemo } from 'react';
import { Form } from 'components/inputs';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { EXERCISE_TYPE_OPTIONS } from 'constants/global';
import { yupResolver } from '@hookform/resolvers/yup';
import ButtonsWrapper, { ButtonVariantEnum } from 'components/buttons/ButtonWrapper';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { format, isValid } from 'date-fns';
import Block from './components/Block';
import blockValidation from './validation';

const Content = () => {
  const params = useParams<{ courseId: string }>();
  const courseId = params.courseId as string;
  const { addToast } = useContext(ToastContext);
  const { data, loading, error, refetch } = useBlocksQuery({
    variables: { filter: { courseId }, page: 0, limit: 1000 },
  });
  const blocks = useMemo(() => data?.blocks.data || [], [data]);

  const form = useForm({
    resolver: yupResolver(blockValidation),
    values: {
      // @ts-ignore
      blocks: blocks?.map((block) => ({
        title: block.title,
        trial: block.availabilityType === BlockAvailabilityType.Trial,
        id: block.id,
        coverFileUrl: block.coverFile?.url,
        dateFrom: block.dateFrom,
        dateTo: block.dateTo,
        cost: block.cost,
        lessons: block?.lessons?.map((lesson) => ({
          title: lesson.title,
          trial: lesson.trial,
          dateFrom: lesson.dateFrom,
          dateTo: lesson.dateTo,
          id: lesson.id,
          exercises: lesson?.exercises?.map((exercise) => ({
            ...exercise,
            timeLimit: exercise.timeLimit ? new Date(exercise.timeLimit * 1000).toISOString().substring(11, 19) : '',
            isTimeLimit: !!exercise.timeLimit,
            type: EXERCISE_TYPE_OPTIONS.find((type: any) => exercise.type === type.id),
          })),
        })),
      })),
    },
  });
  const { control, handleSubmit } = form;

  const fieldArray = useFieldArray({
    control,
    keyName: 'formId',
    name: 'blocks',
  }) as unknown as UseFieldArrayReturn;
  const { fields, append } = fieldArray;

  const handleAddBlock = useCallback(() => {
    append({ title: '', trial: false, id: '', coverFileUrl: '', dateFrom: '', dateTo: '', cost: '', lessons: [] });
  }, [append]);

  const [updateBlock, { loading: updateBlockLoading }] = useUpdateBlocksMutation();

  const onSubmit = (formData: any) => {
    const getCoverFile = (block: any) => {
      if (!block.coverFile && !block.coverFileUrl) return null;
      if (block.coverFile?.preview) return block.coverFile;
      return undefined;
    };
    const checkDate = (date: any) =>
      date && typeof date !== 'string' && isValid(date) ? format(date, 'yyyy-MM-dd') : null;

    const convertTime = (time: string | null | undefined) => {
      if (!time) return null;
      const [hours, minutes, seconds] = time.match(/.{1,2}/g) ?? [];
      if (hours && minutes && seconds) return +hours * 60 * 60 + +minutes * 60 + +seconds;
      return null;
    };

    const getAvailabilityType = (block: any) => {
      if (block.trial) return BlockAvailabilityType.Trial;
      if (block.lessons.some((lesson: any) => lesson.trial)) return BlockAvailabilityType.Mixed;
      return BlockAvailabilityType.Payable;
    };

    updateBlock({
      variables: {
        input: {
          courseId,
          blocks: formData.blocks.map((block: any) => ({
            title: block.title,
            id: block.id,
            availabilityType: getAvailabilityType(block),
            cost: block.cost || 0,
            dateFrom: checkDate(block.dateFrom),
            dateTo: checkDate(block.dateTo),
            coverFile: getCoverFile(block),
            lessons: block.lessons?.length
              ? block.lessons?.map((lesson: any) => ({
                  id: lesson.id,
                  title: lesson.title,
                  trial: lesson.trial,
                  dateFrom: checkDate(lesson.dateFrom),
                  dateTo: checkDate(lesson.dateTo),
                  exercises: lesson.exercises?.map(
                    // @ts-ignore
                    (exercise: {
                      isTimeLimit: any;
                      inProgress: boolean;
                      id?: string;
                      title: string;
                      type: string | { id: string; displayName: string };
                      timeLimit?: string | null | undefined;
                    }) => ({
                      id: exercise.id,
                      title: exercise.title,
                      type: typeof exercise.type === 'string' ? exercise.type : exercise.type.id,
                      inProgress: exercise.inProgress,
                      timeLimit: exercise.isTimeLimit ? convertTime(exercise.timeLimit) : null,
                    }),
                  ),
                }))
              : undefined,
          })),
        },
      },
    })
      .then(() => {
        addToast({ type: ToastTypeEnum.SUCCESS });
        refetch();
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const onError = () => addToast({ type: ToastTypeEnum.ERROR });

  if (loading || !!error) return <CircularLoading />;

  return (
    <Form form={form} onSubmit={handleSubmit(onSubmit, onError)}>
      <Stack spacing={2}>
        {fields.map((block: any, index: number) => (
          <ButtonsWrapper
            data={block}
            fieldArray={fieldArray}
            index={index}
            variant={ButtonVariantEnum.BLOCK}
            key={`block-${block.formId}`}
          >
            <Block parentName={`blocks[${index}]`} {...{ form, block, blockIndex: index, blocks }} />
          </ButtonsWrapper>
        ))}
        <Button startIcon={<AddIcon />} onClick={handleAddBlock} sx={{ width: 'fit-content' }}>
          блок
        </Button>
        <LoadingButton
          type='submit'
          variant='contained'
          loading={updateBlockLoading}
          loadingIndicator='Загрузка'
          sx={{ width: 200, position: 'fixed', bottom: 16, right: 24, zIndex: 999 }}
        >
          Сохранить
        </LoadingButton>
      </Stack>
    </Form>
  );
};

export default Content;
