import { Card, Stack, Typography } from '@mui/material';
import { UserAnswerQuery, UserAnswerStatus, useUserAnswerQuery } from '@generated/graphql';
import { useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import { ButtonWithFile } from 'components/inputs';
import { formatISODate } from 'helpers/date';
import { UserInfo, ExerciseInfo, AnswerForm, HomeworkInfo, HistoryMessages } from './components';

export type UserAnswerElement = NonNullable<UserAnswerQuery['userAnswer']>['element'] | undefined;

const Exercise = () => {
  const { userAnswerId } = useParams<{ userAnswerId: string }>();

  const { data, loading, refetch } = useUserAnswerQuery({ variables: { userAnswerId: userAnswerId! } });

  const userAnswer = data?.userAnswer;
  const user = userAnswer?.user;
  const element: UserAnswerElement = userAnswer?.element;
  const status = userAnswer?.status;
  const studentId = userAnswer?.user.id;

  const elementMaxScore = element && 'maxScore' in element ? element.maxScore : undefined;

  const userAnswerHistory = data?.userAnswerHistory || [];

  const currentScore = [...userAnswerHistory]?.reverse()?.find((history) => history.score !== null)?.score;

  const messages = userAnswerHistory.filter((item) => item.message || item.files?.length);

  if (loading) return <CircularLoading />;

  return (
    <Stack spacing={2}>
      <Card component={Stack} direction='row'>
        <HomeworkInfo userAnswer={userAnswer} />
        {!!user && <UserInfo user={user} />}
      </Card>
      <ExerciseInfo element={element} />
      <Card component={Stack} spacing={2}>
        <Stack spacing={1} direction='row' alignItems='center'>
          <Typography variant='h5' mb={2}>
            Ответ пользователя
          </Typography>
          {!!userAnswer?.createdAt && (
            <Typography variant='text6'>{formatISODate(userAnswer?.createdAt, 'dd.MM.yyyy HH:mm')}</Typography>
          )}
        </Stack>
        {userAnswer?.answer && (
          <Stack spacing={1}>
            <Typography sx={{ color: 'base.400' }}>Сообщение:</Typography>
            <Typography
              variant='text3'
              sx={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{
                __html: userAnswer.answer,
              }}
            />
          </Stack>
        )}
        {!!userAnswer?.files?.length &&
          userAnswer.files.map((currentFile) => <ButtonWithFile file={currentFile.file} />)}
      </Card>
      <HistoryMessages messages={messages} studentId={studentId!} />
      {(status === UserAnswerStatus.InProgress || status === UserAnswerStatus.ReplyReceived) && (
        <AnswerForm maxScore={elementMaxScore || 0} currentScore={currentScore} refetchMessages={refetch} />
      )}
    </Stack>
  );
};

export default Exercise;
