import React, { FC } from 'react';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { Close, InsertDriveFileOutlined } from '@mui/icons-material';
import { IFile } from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/types';
import { checkIsFileImg } from 'helpers/common';

type ButtonWithFileProps = {
  onDelete?: () => any;
  file?: IFile['file'];
  disabled?: boolean;
  sx?: SxProps;
  fileName?: string;
};

const ButtonWithFile: FC<ButtonWithFileProps> = ({ onDelete, file, disabled, sx, fileName }) => (
  <Button
    sx={{ width: 'fit-content', ...sx }}
    variant='outlined'
    size='small'
    endIcon={onDelete && !disabled ? <Close onClick={onDelete} /> : undefined}
    startIcon={
      file && checkIsFileImg(file?.name) ? (
        <Box component='img' sx={{ width: 24, height: 24 }} src={file?.url || file?.preview} />
      ) : (
        <InsertDriveFileOutlined />
      )
    }
  >
    <Typography
      sx={{ width: 160, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
      onClick={() => {
        if (file?.url) {
          window.location.href = file.url;
        }
      }}
    >
      {fileName || file?.name || 'file'}
    </Typography>
  </Button>
);

export default ButtonWithFile;
