import { object, string } from 'yup';

const descriptionValidation = object().shape({
  title: string().required(' '),
  subject: object()
    .shape({ id: string().required(' '), displayName: string().required(' ') })
    .required(' '),
  grade: object()
    .shape({ id: string().required(' '), displayName: string().required(' ') })
    .required(' '),
  // colorTheme: object()
  //   .shape({ id: string().required(' '), color: string().required(' ') })
  //   .required(' '),
  dateFrom: string().required(' '),
  dateTo: string().required(' '),
  timeFrom: string().required(' '),
  timeTo: string().required(' '),
  description: string().required(' '),
});

export default descriptionValidation;
