import { formatISODate } from 'helpers/date';
import { USER_COURSE_TYPE_NAME } from 'constants/global';
import { UserCoursesQuery, useRemoveUserFromCourseMutation } from '@generated/graphql';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext } from 'react';
import { ModalContext } from 'context/modalContext';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { ArrayElement } from 'types/common';

type UserCourse = ArrayElement<UserCoursesQuery['userCourses']['data']> | null | undefined;

const COURSES_TABLE = (refetch: any) => {
  const { handleOpen } = useContext(ModalContext);
  const { addToast } = useContext(ToastContext);
  const [removeUserFromCourse] = useRemoveUserFromCourseMutation();
  return [
    {
      title: 'ID курса',
      value: (userCourse: UserCourse) => userCourse?.course.numericId || '—',
      loadingValue: '1355',
    },
    {
      title: 'Название курса',
      value: (userCourse: UserCourse) => userCourse?.course.title || '—',
      loadingValue: 'название курса',
    },
    {
      title: 'Класс',
      value: (userCourse: UserCourse) => (userCourse?.course.grade ? `${userCourse.course.grade} класс` : '—'),
      loadingValue: '11 класс',
    },
    {
      title: 'Тип доступа',
      value: (userCourse: UserCourse) => (userCourse?.type ? USER_COURSE_TYPE_NAME[userCourse.type] : '—'),
      loadingValue: 'название курса',
    },
    {
      title: 'Дата приобретения',
      value: (userCourse: UserCourse) => (userCourse?.createdAt ? formatISODate(userCourse.createdAt) : '—'),
      loadingValue: '20,20,20223',
    },

    {
      title: 'Действие',
      value: (userCourse: UserCourse) => (
        <IconButton
          size='large'
          color='error'
          onClick={(e) => {
            e.stopPropagation();
            handleOpen({
              title: 'Удалить пользователя',
              content: `Вы уверены, что хотите удалить пользователя из курса «${userCourse?.course.title}»?`,
              handleClick: async () =>
                removeUserFromCourse({
                  variables: { userId: userCourse?.userId!, courseId: userCourse?.courseId! },
                })
                  .then(() => {
                    addToast({ type: ToastTypeEnum.SUCCESS });
                    refetch();
                  })
                  .catch(() => addToast({ type: ToastTypeEnum.ERROR })),
            });
          }}
        >
          <CloseIcon />
        </IconButton>
      ),
      tooltip: () => 'Удалить пользователя из курса',
      loadingValue: 'Х',
    },
  ];
};

export default COURSES_TABLE;
