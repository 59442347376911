import { Link, Stack, Typography } from '@mui/material';
import ROUTES from 'constants/routes';
import { createUserName } from 'helpers/common';
import { UserAnswerQuery } from '@generated/graphql';

interface IUserInfoProps {
  user: NonNullable<UserAnswerQuery['userAnswer']>['user'];
}

const UserInfo = ({ user }: IUserInfoProps) => (
  <Stack spacing={1} flex={1}>
    <Typography>{`ID: ${user?.numericId}`}</Typography>
    <Link href={`/${ROUTES.USERS}/${user?.id}`} rel='noopener noreferrer' variant='body1'>
      {`ФИО: ${createUserName(user)}`}
    </Link>
  </Stack>
);

export default UserInfo;
