import { CourseCuratorsQuery, useCourseCuratorsQuery, useUpdateCourseCuratorsMutation } from '@generated/graphql';
import { useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import CuratorCard from 'pages/Course/view/tabs/Curators/CuratorCard';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import UserListModal from 'pages/Course/view/tabs/Curators/UserListModal';
import { useFieldArray, useForm } from 'react-hook-form';
import { ArrayElement } from 'types/common';
import { Form } from 'components/inputs';
import { LoadingButton } from '@mui/lab';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';

export type CourseCurator = ArrayElement<CourseCuratorsQuery['courseCurators']>;

const Curators = () => {
  const { addToast } = useContext(ToastContext);
  const { courseId } = useParams<{ courseId: string }>();
  const { data, loading } = useCourseCuratorsQuery({
    variables: {
      courseId: courseId!,
    },
  });

  const curators = data?.courseCurators;

  const form = useForm<{ curators: CourseCuratorsQuery['courseCurators'] }>();
  const { reset, control, handleSubmit } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'curators',
    keyName: 'formId',
  });

  useEffect(() => {
    reset({ curators });
  }, [JSON.stringify(curators)]);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const onAddCurators = () => setOpenModal(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateCurators, { loading: updateLoading }] = useUpdateCourseCuratorsMutation();

  const onSave = (formData: { curators: CourseCuratorsQuery['courseCurators'] }) => {
    const ids = formData?.curators?.map((curator) => ({ curatorId: curator.user.id })) || [];
    updateCurators({
      variables: {
        input: {
          courseId: courseId!,
          curatorsInput: ids,
        },
      },
    })
      .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  if (loading) return <CircularLoading />;

  return (
    <Form form={form}>
      <Grid container rowGap={2}>
        {fields?.map((curator, index: number) => (
          <Grid item xs={3} key={curator.formId}>
            <CuratorCard curator={curator} onDelete={() => remove(index)} />
          </Grid>
        ))}
        <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Tooltip title='Добавить куратора'>
            <IconButton onClick={onAddCurators} size='large' sx={{ bgcolor: 'base.200' }}>
              <Add />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton onClick={handleSubmit(onSave)} loading={updateLoading} variant='contained'>
            Сохранить
          </LoadingButton>
        </Grid>
      </Grid>
      {openModal && <UserListModal {...{ openModal, setOpenModal, append, fields }} />}
    </Form>
  );
};
export default Curators;
