import { Card, Stack, Typography } from '@mui/material';
import { UserAnswerElement } from 'pages/Homework/view/tabs/Exercise';

interface IExerciseInfoProps {
  element: UserAnswerElement;
}

const ExerciseInfo = ({ element }: IExerciseInfoProps) => {
  const elementTitle = element && 'title' in element ? element.title : undefined;
  const elementDescription = element && 'description' in element ? element.description : undefined;
  return (
    <Card>
      <Stack spacing={1}>
        <Typography variant='h6'>{elementTitle}</Typography>
        <Typography sx={{ whiteSpace: 'pre-wrap' }}>{elementDescription}</Typography>
      </Stack>
    </Card>
  );
};

export default ExerciseInfo;
