import { Skeleton, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { FC } from 'react';
import { ITableStructure } from 'layouts/TableLayout';
/*
eslint-disable react/no-array-index-key
*/

type SkeletonTableProps = {
  rows: number;
  columns: ITableStructure[];
};

const SkeletonTable: FC<SkeletonTableProps> = ({ rows, columns }) => (
  <TableBody>
    {Array(rows)
      .fill(0)
      .map((row, rowIndex) => (
        <TableRow key={`skeleton-row-${rowIndex}`}>
          {columns.map((col, colIndex) => {
            if (col.hide) return null;
            return (
              <TableCell align={col.align} key={`skeleton-col-${colIndex}`}>
                <Skeleton variant={col.loadingValue === 'icon' ? 'circular' : 'text'}>
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      fontSize: '0.875rem',
                      lineHeight: '1.43',
                      display: 'block',
                    }}
                  >
                    {col.loadingValue}
                  </Typography>
                </Skeleton>
              </TableCell>
            );
          })}
        </TableRow>
      ))}
  </TableBody>
);

export default SkeletonTable;
