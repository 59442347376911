import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import CopyButton from 'components/buttons/CopyButton';
import { GeneratePasswordResult } from 'pages/User/view/HeaderButtons';

interface ShowNewPasswordModalProps {
  setOpen: Dispatch<SetStateAction<GeneratePasswordResult>>;
  data: GeneratePasswordResult;
}

const ShowNewPasswordModal: FC<ShowNewPasswordModalProps> = ({ data, setOpen }) => (
  <Dialog open={!!data} onClose={() => setOpen(undefined)}>
    <DialogTitle>
      <Typography variant='h3' textAlign='center'>
        Данные для входа
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Stack alignItems='center'>
        <Box>
          <CopyButton copyText={data?.phoneNumber!}>
            <Typography variant='body1'>{`Логин: ${data?.phoneNumber}`}</Typography>
          </CopyButton>
          <CopyButton copyText={data?.password!}>
            <Typography variant='body1'>{`Пароль: ${data?.password}`}</Typography>
          </CopyButton>
        </Box>
      </Stack>
      <Alert severity='warning' variant='outlined' sx={{ mt: 3 }}>
        Сохраните данные для входа!
        <br />
        После закрытия окна их невозможно будет просмотреть повторно
      </Alert>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpen(undefined)}>Закрыть</Button>
    </DialogActions>
  </Dialog>
);

export default ShowNewPasswordModal;
