import React, { useState } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import onCreateCroppedPreview from 'components/inputs/control/Dropzone/onCreateCroppedPreview';
import { centerAspectCrop } from 'components/inputs/control/Dropzone/utils/cropUtils';
import { FilePreview } from 'types/common';
import { DroppedFile } from 'components/inputs/control/Dropzone';
import PhotoUploadModal from './PhotoUploadModal';

type CropModalProps = {
  open: boolean;
  onClose: () => void;
  file: NonNullable<DroppedFile>;
  imgRef: React.RefObject<HTMLImageElement>;
  aspect: number;
  setValue: (name: string, file: FilePreview | string) => void;
  name?: string;
  nameUrl?: string;
  previewCanvasRef: React.RefObject<HTMLCanvasElement>;
};

const CropModal = ({
  open,
  onClose,
  file,
  imgRef,
  aspect,
  setValue,
  name,
  nameUrl,
  previewCanvasRef,
}: CropModalProps) => {
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const uploadNewImage = (newFile: FilePreview) => {
    setValue(name || 'coverFile', newFile);
    setValue(nameUrl || 'coverFileUrl', newFile.preview);
    onClose();
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
  }
  return (
    <PhotoUploadModal
      open={open}
      onClose={onClose}
      content={
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          minHeight={100}
        >
          <img
            ref={imgRef}
            alt='Crop img modal'
            src={file.base64 as string}
            style={{
              height: '100%',
              width: '100%',
              minWidth: '400px',
            }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      }
      onAction={() => {
        onCreateCroppedPreview({
          completedCrop,
          previewCanvasRef,
          imgRef,
          uploadNewImage,
          droppedFile: file,
        });
      }}
    />
  );
};

export default CropModal;
