import TableLayout from 'layouts/TableLayout';
import React from 'react';
import { useUserCoursesQuery } from '@generated/graphql';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from 'constants/routes';
import filterFields from './filterFields';
import USER_COURSES_TABLE from './table';

const Courses = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useUserCoursesQuery({
    variables: {
      filter: {
        userId: userId!,
      },
      page: 0,
      limit: 10,
    },
  });
  const tableStructure = USER_COURSES_TABLE(refetch);
  const courses = data?.userCourses?.data;
  const totalCount = data?.userCourses?.totalCount;
  return (
    <TableLayout
      {...{ error, totalCount, refetch, tableStructure }}
      defaultFilter={{
        userId: userId!,
      }}
      action={(row) => navigate(`/${ROUTES.COURSES}/${row.courseId}/user/${userId}`)}
      loading={loading}
      data={courses}
      filtersFunc={filterFields}
    />
  );
};

export default Courses;
