import React, { FC, ReactNode, useContext, useState } from 'react';
import { Stack } from '@mui/material';
import { ModalContext } from 'context/modalContext';
import { useNavigate } from 'react-router-dom';
import ActionButton, { ActionButtonEnum } from 'components/buttons/ActionButton';
import { UseFieldArrayReturn } from 'react-hook-form';

export enum ButtonVariantEnum {
  BLOCK = 'BLOCK',
  LESSON = 'LESSON',
  LESSON_PART = 'LESSON_PART',
  ELEMENT = 'ELEMENT',
}

type ButtonWrapperProps = {
  children: ReactNode;
  index: number;
  variant: ButtonVariantEnum;
  data: any;
  fieldArray: UseFieldArrayReturn;
  disabled?: boolean;
};

type ButtonVariant = {
  [key in ButtonVariantEnum]: {
    title: string;
    buttons: string[];
    removeButtonCondition: boolean;
    url?: string;
  };
};

const ButtonsWrapper: FC<ButtonWrapperProps> = ({ children, index, variant, data, fieldArray, disabled = false }) => {
  const { handleOpen } = useContext(ModalContext);
  const { fields, remove, swap } = fieldArray;

  const [hover, setHover] = useState<boolean>(false);
  const navigate = useNavigate();

  const VARIANTS: ButtonVariant = {
    BLOCK: {
      title: 'блок',
      buttons: ['remove', 'swap'],
      removeButtonCondition: true,
    },
    LESSON: {
      title: 'урок',
      buttons: ['swap', 'remove'],
      removeButtonCondition: true,
    },
    LESSON_PART: {
      title: 'часть урока',
      buttons: ['swap', 'remove', 'edit'],
      removeButtonCondition: true,
      url: data.id,
    },
    ELEMENT: {
      title: 'элемент',
      buttons: ['swap', 'remove'],
      removeButtonCondition: true,
    },
  };

  return (
    <Stack sx={{ flexDirection: 'row', minHeight: 52, minWidth: '100%' }}>
      {children}
      <Stack
        sx={{ width: '28px', ml: 0.5, justifyContent: 'flex-start' }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <>
          {VARIANTS[variant].buttons.includes('swap') && (
            <>
              {index !== 0 && (
                <ActionButton
                  isFilled={hover}
                  handleClick={() => {
                    swap(index, index - 1);
                    setHover(false);
                  }}
                  type={ActionButtonEnum.MOVE_UP}
                  disabled={disabled}
                />
              )}
              {index !== fields.length - 1 && (
                <ActionButton
                  isFilled={hover}
                  handleClick={() => {
                    swap(index, index + 1);
                    setHover(false);
                  }}
                  type={ActionButtonEnum.MOVE_DOWN}
                  disabled={disabled}
                />
              )}
            </>
          )}

          {VARIANTS[variant].buttons.includes('remove') && VARIANTS[variant].removeButtonCondition && (
            <>
              <ActionButton
                isFilled={hover}
                disabled={disabled}
                handleClick={() =>
                  handleOpen({
                    title: `Удалить ${VARIANTS[variant].title}`,
                    content: `Вы уверены, что хотите удалить ${VARIANTS[variant].title}${
                      data?.title ? ` «${data?.title}»` : ''
                    }?`,
                    handleClick: async () => {
                      remove(index);
                    },
                  })
                }
                type={ActionButtonEnum.DELETE}
              />
              {VARIANTS[variant].buttons.includes('edit') && data.id && (
                <ActionButton
                  disabled={disabled}
                  isFilled={hover}
                  handleClick={() => navigate(VARIANTS[variant].url!)}
                  type={ActionButtonEnum.EDIT}
                />
              )}
            </>
          )}
        </>
      </Stack>
    </Stack>
  );
};

export default ButtonsWrapper;
