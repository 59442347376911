import { Container, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Form, TextFieldControl } from 'components/inputs';
import { LoginByCredentialsMutationVariables, useLoginByCredentialsMutation } from '@generated/graphql';
import { LoadingButton } from '@mui/lab';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { authRedirect } from 'helpers/cookieHelper';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

type FormName = keyof LoginByCredentialsMutationVariables;
type FormNames = FormName[];

const schema = object().shape({
  phoneNumber: string().required(' '),
  password: string().required(' '),
});

const Auth = () => {
  const [searchParams] = useSearchParams();
  const { redirectUrl } = useMemo(() => Object.fromEntries(searchParams), [searchParams]);
  const form = useForm<LoginByCredentialsMutationVariables>({ resolver: yupResolver(schema) });
  const { handleSubmit, setError } = form;
  const [loginByCredentials, { loading }] = useLoginByCredentialsMutation();
  const onSubmit = (data: LoginByCredentialsMutationVariables) => {
    loginByCredentials({
      variables: data,
    })
      .then((res: any) => {
        const {
          data: {
            loginByCredentials: { accessToken, refreshToken },
          },
        } = res;
        authRedirect({ accessToken, refreshToken, redirectUrl });
      })
      .catch(() => {
        const names = ['phoneNumber', 'password'] as FormNames;
        names.forEach((name) => setError(name, { message: 'Неверный логин или пароль' }));
      });
  };
  return (
    <Container sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Form form={form}>
        <Stack spacing={4}>
          <Typography variant='h3' sx={{ textAlign: 'center' }}>
            Вход
          </Typography>
          <TextFieldControl name='phoneNumber' label='Номер телефона' maskProps={{ mask: '+{7} (000) 000-00-00' }} />
          <TextFieldControl name='password' type='password' label='Пароль' />
          <LoadingButton onClick={handleSubmit(onSubmit)} variant='contained' loading={loading}>
            Войти
          </LoadingButton>
        </Stack>
      </Form>
    </Container>
  );
};

export default Auth;
