import { useCourseUsersQuery } from '@generated/graphql';
import { useNavigate, useParams } from 'react-router-dom';
import TableLayout from 'layouts/TableLayout';
import USERS_TABLE from 'pages/Course/view/tabs/Users/table';
import filterFields from 'pages/Course/view/tabs/Users/filterFields';
import { Box, Button, Stack } from '@mui/material';
import useModal from 'hooks/useModal';
import AddUserToCourseModal from './components/AddUserToCourseModal';

const Users = () => {
  const navigate = useNavigate();
  const { courseId } = useParams<{ courseId: string }>();

  const {
    open: openAddUserModal,
    handleOpen: handleOpenAddUserModal,
    handleClose: handleCloseAddUserModal,
  } = useModal();

  const defaultFilters = {
    courseId: courseId!,
  };
  const { data, loading, error, refetch } = useCourseUsersQuery({
    variables: {
      courseId: courseId!,
      filter: {
        courseId: courseId!,
      },
      limit: 10,
      page: 0,
    },
  });

  const users = data?.courseUsers.data;
  const totalCount = data?.courseUsers.totalCount;
  const course = data?.course;

  const toggleCloseAddUserModal = () => {
    handleCloseAddUserModal();
    refetch();
  };

  return (
    <>
      <Stack spacing={2}>
        <Box>
          <Button
            sx={{ ml: 'auto', display: 'block' }}
            size='small'
            onClick={handleOpenAddUserModal}
            variant='contained'
          >
            Добавить пользователя
          </Button>
        </Box>
        <TableLayout
          data={users}
          refetch={refetch}
          loading={loading}
          totalCount={totalCount}
          tableStructure={USERS_TABLE(refetch, course)}
          defaultFilter={defaultFilters}
          error={error}
          filtersFunc={filterFields}
          action={(user) => navigate(`user/${user.id}`)}
        />
      </Stack>
      <AddUserToCourseModal open={openAddUserModal} onClose={toggleCloseAddUserModal} />
    </>
  );
};

export default Users;
