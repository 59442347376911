import { SettingsOutlined, PeopleOutline, AutoStoriesOutlined } from '@mui/icons-material';
import ROUTES from 'constants/routes';

const routes = () => [
  {
    title: 'Курсы',
    href: ROUTES.COURSES,
    icon: SettingsOutlined,
  },
  {
    title: 'Пользователи',
    href: ROUTES.USERS,
    icon: PeopleOutline,
  },
  {
    title: 'Домашние задания',
    href: ROUTES.HOMEWORKS,
    icon: AutoStoriesOutlined,
  },
];

export default routes;
