import React, { useRef, useState } from 'react';
import { Box } from '@mui/material';
import { FilePreview } from 'types/common';
import ImageUploader from './components/ImageUploader';
import CropModal from './components/CropModal';

type DropzoneProps = {
  setValue: (name: string, file: FilePreview | string) => void;
  disabled?: boolean;
  height?: number;
  width?: number;
  text?: string;
  name?: string;
  nameUrl?: string;
  aspect?: number;
};

export type DroppedFile = {
  base64: string | ArrayBuffer | null;
  file: File;
} | null;

const Dropzone = ({
  setValue,
  disabled,
  height,
  width,
  text,
  name = 'coverFile',
  nameUrl = 'coverFileUrl',
  aspect = 1,
}: DropzoneProps) => {
  const [file, setFile] = useState<DroppedFile>(null);
  const [openCropModal, setOpenCropModal] = useState<boolean>(false);

  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const onClose = () => {
    setOpenCropModal(false);
  };

  return (
    <Box sx={{ height: height || '100%' }}>
      <ImageUploader
        setOpenCropModal={setOpenCropModal}
        setFile={setFile}
        width={width}
        text={text}
        disabled={disabled}
      />
      <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
      {openCropModal && file && (
        <CropModal
          open={openCropModal}
          onClose={onClose}
          setValue={setValue}
          file={file}
          imgRef={imgRef}
          aspect={aspect}
          previewCanvasRef={previewCanvasRef}
          name={name}
          nameUrl={nameUrl}
        />
      )}
    </Box>
  );
};

export default Dropzone;
