import { Editor, FileLoader, UploadResponse } from 'ckeditor5';
import { graphqlLink } from 'config/apolloConfig';
import cookies from 'js-cookie';

class ImageUploadAdapter {
  private readonly loader: FileLoader;

  constructor(loader: FileLoader) {
    this.loader = loader;
  }

  public upload(): Promise<UploadResponse> {
    return this.loader.file.then(
      (file: File | null) =>
        new Promise((resolve, reject) => {
          ImageUploadAdapter.uploadFile(resolve, reject, file);
        }),
    );
  }

  static uploadFile(resolve: (value: UploadResponse) => void, reject: (reason?: any) => void, file: File | null) {
    if (!file) {
      reject('No file found');
      return;
    }

    const accessToken = cookies.get('accessToken');

    if (!accessToken) {
      reject('No access token');
      return;
    }

    const operations = JSON.stringify({
      query: `mutation ($file: Upload!) {
        uploadFile(file: $file) {
          id
          name
          url
        }
      }`,
      variables: { file: null },
    });
    const map = JSON.stringify({ 0: ['variables.file'] });

    const bodyData = new FormData();

    bodyData.append('operations', operations);
    bodyData.append('map', map);
    bodyData.append('0', file);

    const response = fetch(graphqlLink, {
      method: 'POST',
      headers: {
        'Apollo-Require-Preflight': 'true',
        Authorization: `JWT ${accessToken}`,
      },
      body: bodyData,
    });

    response
      .then((res) => res.json())
      .then((res: { data: { uploadFile: { id: string; url: string; name: string } } }) => {
        resolve({
          default: res.data.uploadFile.url,
        });
      })
      .catch((err) => {
        reject(err);
      });
  }
}

export default function ImageUploadAdapterPlugin(editor: Editor) {
  // eslint-disable-next-line no-param-reassign
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => new ImageUploadAdapter(loader);
}
