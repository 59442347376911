import { useState } from 'react';

const useModal = (initialState = false) => {
  const [open, setOpen] = useState(initialState);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return { open, handleOpen, handleClose };
};

export default useModal;
