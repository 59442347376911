import React, { FC } from 'react';
import { Stack } from '@mui/material';
import EditorControl from 'components/inputs/control/EditorControl';

type TextElementProps = {
  elementIndex: number;
  disabled?: boolean;
};

const Text: FC<TextElementProps> = ({ elementIndex, disabled }) => (
  <Stack sx={{ mt: 3 }}>
    <EditorControl
      label='Текст'
      name={`elements.[${elementIndex}].content`}
      rules={{ required: true }}
      disabled={disabled}
    />
  </Stack>
);

export default Text;
