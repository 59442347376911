import { GetCourseHeaderQuery, useCopyCourseMutation } from '@generated/graphql';
import Modal from 'components/Modal';
import { useContext } from 'react';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { Form, TextFieldControl } from 'components/inputs';
import { useForm, UseFormReturn } from 'react-hook-form';

interface ICopyCourseModalProps {
  open: boolean;
  onClose: () => void;
  course: GetCourseHeaderQuery['course'];
}

interface ICopyCourseForm {
  title: string;
}

const CopyCourseForm = ({ form }: { form: UseFormReturn<ICopyCourseForm> }) => (
  <Form form={form}>
    <TextFieldControl name='title' rules={{ required: true }} label='Название нового курса' />
  </Form>
);

const CopyCourseModal = ({ open, onClose, course }: ICopyCourseModalProps) => {
  const { addToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const form = useForm<ICopyCourseForm>({
    defaultValues: {
      title: course.title || '',
    },
  });
  const { handleSubmit } = form;

  const [copyCourse, { loading }] = useCopyCourseMutation();

  const onError = () => addToast({ type: ToastTypeEnum.ERROR });

  const handleCopyCourse = async (formData: ICopyCourseForm) => {
    await copyCourse({
      variables: {
        input: {
          id: course.id,
          title: formData.title,
        },
      },
    })
      .then((res) => {
        const newCourseId = res.data?.copyCourse.id;
        if (newCourseId) {
          navigate(`/${ROUTES.COURSES}/${newCourseId}`);
          addToast({ type: ToastTypeEnum.SUCCESS, text: 'Копия курса создана' });
          onClose();
        } else onError();
      })
      .catch(() => onError());
  };

  return (
    <Modal
      onClose={onClose}
      handleClick={handleSubmit(handleCopyCourse, onError)}
      content={<CopyCourseForm form={form} />}
      title='Создать копию курса'
      buttonText='Копировать'
      open={open}
      loading={loading}
    />
  );
};

export default CopyCourseModal;
