import { Crop, makeAspectCrop, centerCrop } from 'react-image-crop';
import { SyntheticEvent } from 'react';

export function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number): Crop {
  return centerCrop(makeAspectCrop({ unit: '%', width: 90 }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
}

export function onImageLoad(e: SyntheticEvent<HTMLImageElement>, setCrop: (crop: Crop) => void, aspect: number) {
  const { width, height } = e.currentTarget;
  setCrop(centerAspectCrop(width, height, aspect));
}
