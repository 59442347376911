import React, { FC, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import { Button, Grid } from '@mui/material';
import { generateQueryOnSubmit, onClearFilter, onUseEffect } from 'components/generators/filtersGenerator';
import GeneratedInput, { InputTypes } from 'components/generators/inputsGenerator';
import { useSearchParams } from 'react-router-dom';
import qs from 'qs';
import { UseFormReturn, UseFormWatch } from 'react-hook-form';
import { Form } from 'components/inputs';

interface IFilter {
  name: string;
  watch: UseFormWatch<any>;
  label?: string;
  inputType: InputTypes;
  optionName?: string;
}

type FiltersGroupProps = {
  filters: IFilter[];
  form: UseFormReturn;
};

const Filters: FC<FiltersGroupProps> = ({ filters, form }) => {
  const { reset, handleSubmit, control, watch } = form;
  const [searchParams, setSearchParams] = useSearchParams();
  const query = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);

  const onSubmit = (data: any) => {
    generateQueryOnSubmit(data, filters, setSearchParams);
  };

  const onClear = () => onClearFilter(filters, reset, setSearchParams);

  useEffect(() => {
    onUseEffect(filters, reset, query);
  }, [query]);

  return (
    <Box>
      <Form form={form}>
        <Grid container spacing={3} component='form' autoComplete='false' alignItems='flex-end'>
          {filters.map((input) => (
            <Grid item xs={12} md={3} key={`filter-input-${input.name}`}>
              <GeneratedInput {...{ input, control, watch }} />
            </Grid>
          ))}
          <Grid item container xs={12} justifyContent='flex-end'>
            <Button onClick={onClear} sx={{ marginRight: (theme) => theme.spacing(2) }}>
              Сбросить
            </Button>
            <Button variant='contained' type='submit' onClick={handleSubmit(onSubmit)} size='small'>
              Применить
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
};

export default Filters;
