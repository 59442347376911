const colors = {
  error: {
    light: '#E5AAAA',
    main: '#FF6C6C',
  },
  base: {
    100: '#FFFFFF',
    200: '#E8ECEF',
    300: '#B9CCD6',
    400: '#ACB0B4',
    500: '#798AA8',
    600: '#626E80',
    700: '#283750',
    750: 'rgba(47, 83, 147, 0.7)',
    800: '#01163F',
    900: '#161D27',
  },
};

export default colors;
