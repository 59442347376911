import { Card, Grid, Stack, Typography } from '@mui/material';
import { ButtonWithFile, EditorControl, FileAttachButton, Form, TextFieldControl } from 'components/inputs';
import { LoadingButton } from '@mui/lab';
import { useFieldArray, useForm } from 'react-hook-form';
import { Exact, useProcessAnswerMutation, UserAnswerQuery } from '@generated/graphql';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import React, { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ApolloQueryResult } from '@apollo/client';

interface IFormInputs {
  score: string;
  message?: string;
  files?: { file: File }[];
}

interface IAnswerFormProps {
  maxScore: number;
  currentScore: number | undefined | null;
  refetchMessages: (
    variables?: Partial<Exact<{ userAnswerId: string }>> | undefined,
  ) => Promise<ApolloQueryResult<UserAnswerQuery>>;
}

const AnswerForm: FC<IAnswerFormProps> = ({ maxScore, currentScore, refetchMessages }) => {
  const { userAnswerId } = useParams();

  const { addToast } = useContext(ToastContext);

  const form = useForm<IFormInputs>({ defaultValues: { score: currentScore ? currentScore.toString() : undefined } });
  const { handleSubmit, control } = form;

  const fieldArray = useFieldArray({
    control,
    name: 'files',
    keyName: 'formId',
  });

  const { fields, append, remove } = fieldArray;

  const [processAnswer, { loading: processAnswerLoading }] = useProcessAnswerMutation();

  const onSend = (formInputs: IFormInputs) => {
    processAnswer({
      variables: {
        input: {
          id: userAnswerId!,
          message: formInputs.message,
          score: parseFloat(formInputs.score),
          files: formInputs.files?.length ? formInputs.files : undefined,
        },
      },
    })
      .then(() => {
        addToast({ type: ToastTypeEnum.SUCCESS });
        refetchMessages();
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };
  return (
    <Card component={Stack} spacing={2} sx={{ border: '2px solid rgb(25, 118, 210)' }}>
      <Typography variant='h5'>Ответ куратора</Typography>
      <Form form={form}>
        <Stack spacing={3.5} alignItems='baseline'>
          <TextFieldControl
            label='Количество баллов'
            name='score'
            rules={{ required: true }}
            type='number'
            helperText={`Максимальное количество баллов: ${maxScore}`}
          />
          <EditorControl name='message' label='Комментарий' />

          {!!fields.length && (
            <Grid container columnGap={1} rowGap={2}>
              {fields?.map((file, index: number) => (
                <ButtonWithFile key={file.formId} file={file.file} onDelete={() => remove(index)} />
              ))}
            </Grid>
          )}

          <FileAttachButton
            onChange={(event) => {
              const { files } = event.target;
              if (files) {
                append(Array.from(files).map((file) => ({ file })));
              }
            }}
            multiple
            text='Добавить файл'
          />

          <LoadingButton loading={processAnswerLoading} onClick={handleSubmit(onSend)} variant='contained'>
            Отправить
          </LoadingButton>
        </Stack>
      </Form>
    </Card>
  );
};

export default AnswerForm;
