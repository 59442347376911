import { createBrowserRouter } from 'react-router-dom';
import Error404 from 'pages/Error404';
import MainLayout from 'layouts/MainLayout';
import ROUTES from 'constants/routes';
import Courses from 'pages/Course/list';
import CourseView from 'pages/Course/view';
import Auth from 'pages/Auth';
import ExerciseConstructor from 'pages/Course/view/tabs/Content/components/ExerciseConstructor';
import HomeworkList from 'pages/Homework/list/common';
import HomeworkView from 'pages/Homework/view';
import UsersList from 'pages/User/list';
import UserView from 'pages/User/view';
import UserExercisesList from 'pages/Homework/list/user';
import CourseCards from 'pages/Course/view/Cards';
import UserBlocks from 'pages/Course/view/UserBlocks';

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <Error404 />,
    element: <MainLayout />,
    children: [
      {
        path: 'auth',
        element: <Auth />,
      },
      {
        path: ROUTES.COURSES,
        children: [
          {
            index: true,
            element: <Courses />,
          },
          {
            path: ROUTES.CREATE,
            element: <CourseView />,
          },
          {
            path: ':courseId',
            children: [
              {
                index: true,
                element: <CourseView />,
              },
              {
                path: 'cards',
                element: <CourseCards />,
              },
              {
                path: 'user/:userId',
                element: <UserBlocks />,
              },
            ],
          },
          {
            path: ':courseId/:exerciseId',
            element: <ExerciseConstructor />,
          },
        ],
      },
      {
        path: `${ROUTES.HOMEWORKS}`,
        children: [
          {
            index: true,
            element: <HomeworkList />,
          },
          {
            path: ':userAnswerId',
            element: <HomeworkView />,
          },
          {
            path: ':courseId/:userId',
            element: <UserExercisesList />,
          },
        ],
      },
      {
        path: ROUTES.USERS,
        children: [
          {
            index: true,
            element: <UsersList />,
          },
          {
            path: ':userId',
            element: <UserView />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.AUTH,
    element: <Auth />,
  },
]);

export default router;
