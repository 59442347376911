import React, { FC, useContext } from 'react';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import FileAttachButton from 'components/inputs/control/FileAttachButton';
import { getFileName } from 'helpers/common';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { ExerciseForm, IFile } from '../../../types';

interface AttachDownloadFileProps {
  form: UseFormReturn<ExerciseForm>;
  disabled?: boolean;
  isCover: boolean;
  currentFilePairName: `elements.${number}.filePairs.${number}`;
  filePairFieldArray: UseFieldArrayReturn<ExerciseForm, `elements.${number}.filePairs`, 'formId'>;
  filePairIndex: number;
}

const AttachDownloadFile: FC<AttachDownloadFileProps> = ({
  form,
  currentFilePairName,
  isCover,
  disabled,
  filePairFieldArray,
  filePairIndex,
}) => {
  const { addToast } = useContext(ToastContext);
  const { getValues } = form;
  const { update } = filePairFieldArray;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files ? Array.from(event.target.files)[0] : undefined;
    if (newFile) {
      const currentData: IFile = getValues(`${currentFilePairName}.download`) || {};
      const fileName = getFileName(newFile.name, 'name');

      const newDownloadFile = {
        ...currentData,
        file: newFile,
        fileName: newFile.name,
        fileNameWithoutExtension: fileName,
      };

      const coverFile = getValues(`${currentFilePairName}.cover`);

      update(filePairIndex, {
        cover: coverFile,
        download: newDownloadFile,
      });
    } else {
      addToast({ type: ToastTypeEnum.ERROR, text: 'Файл не был добавлен' });
    }
  };

  return (
    <FileAttachButton
      text={isCover ? 'Прикрепить обложку' : 'Прикрепить файл'}
      onChange={handleFileChange}
      disabled={disabled}
    />
  );
};

export default AttachDownloadFile;
