import React, { FC } from 'react';
import { Stack } from '@mui/material';
import TextFieldControl from 'components/inputs/control/TextFieldControl';

type FeedbackProps = {
  elementIndex: number;
  disabled?: boolean;
};

const Feedback: FC<FeedbackProps> = ({ elementIndex, disabled }) => (
  <Stack sx={{ mt: 3 }}>
    <TextFieldControl
      rows={4}
      multiline
      label='Описание'
      name={`elements.[${elementIndex}].description`}
      rules={{ required: true }}
      disabled={disabled}
    />
  </Stack>
);

export default Feedback;
