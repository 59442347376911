import React, { FC, ReactNode, useState } from 'react';
import { Box, Stack, Tab, Tooltip, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

export interface ITab {
  title: string;
  component: ReactNode;
  value: string;
}

type TabLayoutProps = {
  tabs: ITab[];
  searchParam?: string;
  firstTab?: string;
  endText?: string;
};

const TabLayout: FC<TabLayoutProps> = ({ tabs, searchParam, firstTab, endText }) => {
  const existingSearchParam: string = searchParam ? window.sessionStorage.getItem(searchParam) || '' : '';
  const [tab, setTab] = useState<string>(existingSearchParam || firstTab || 'description');
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    if (searchParam) window.sessionStorage.setItem(searchParam, newValue);
    setTab(newValue);
    window.scrollTo(0, 0);
  };

  return (
    <TabContext value={tab}>
      <Stack
        direction='row'
        sx={{ borderBottom: 1, borderColor: 'divider', position: 'relative', pt: 2, alignItems: 'center' }}
      >
        <TabList onChange={handleChange}>
          {tabs?.map((item, index) => (
            <Tab
              key={`tab-${item.value}`}
              disabled={!searchParam && index !== 0}
              sx={{
                '&.Mui-disabled': {
                  pointerEvents: 'auto',
                },
              }}
              label={
                !searchParam && index !== 0 ? (
                  <Tooltip title='Для доступа к вкладке необходимо заполнить и сохранить вкладку с описанием'>
                    <span>{item.title}</span>
                  </Tooltip>
                ) : (
                  item.title
                )
              }
              value={item.value}
            />
          ))}
        </TabList>
        {!!endText && (
          <>
            <Box component='div' flex={1} />
            <Typography>{endText}</Typography>
          </>
        )}
      </Stack>
      {tabs?.map((item) => (
        <TabPanel key={`tab-content-${item.value}`} value={item.value} sx={{ px: 0 }}>
          {item.component}
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default TabLayout;
