import {
  Add_User_In_Course_Type,
  AdminUserListOrderFields,
  OrderType,
  useAddUserInCourseMutation,
  useUserListQuery,
} from '@generated/graphql';
import React, { useCallback, useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { createUserName } from 'helpers/common';
import Autocomplete from 'components/inputs/Autocomplete';
import { debounce } from 'lodash';

interface IAddUserToCourseModal {
  open: boolean;
  onClose: () => void;
}

type SelectedUser = {
  id: string;
  displayName: string;
};

const AddUserToCourseModal = ({ open, onClose }: IAddUserToCourseModal) => {
  const { courseId } = useParams<{ courseId: string }>();
  const { addToast } = useContext(ToastContext);
  const [addUserInCourse, { loading: loadingMutation }] = useAddUserInCourseMutation();

  const [selectedUser, setSelectedUser] = useState<SelectedUser | null>(null);

  const onSuccess = () => addToast({ type: ToastTypeEnum.SUCCESS });
  const onError = (text?: string) => addToast({ type: ToastTypeEnum.ERROR, text });

  const { data, loading, refetch } = useUserListQuery({
    variables: {
      limit: 10,
      page: 0,
      order: {
        field: AdminUserListOrderFields.NumericId,
        order: OrderType.Asc,
      },
    },
  });

  const userList = data?.users.data?.map((user) => ({
    id: user.id,
    displayName: `ID: ${user.numericId}, ${createUserName(user)}`,
  }));

  const getUserList = (inputValue: string) => {
    const isNumber = !Number.isNaN(Number(inputValue));
    refetch({
      filter: {
        ...(isNumber ? { numericId: Number(inputValue) } : { fullName: inputValue }),
      },
    });
  };

  const handleInputChange = useCallback(
    debounce((event: React.ChangeEvent<{}>, inputValue: string) => {
      getUserList(inputValue);
    }, 300),
    [],
  );

  const onAddUserToCourse = () => {
    if (!selectedUser) {
      onError('Необходимо указать пользователся');
    } else {
      addUserInCourse({
        variables: {
          input: {
            courseId: courseId!,
            accessType: Add_User_In_Course_Type.Trial,
            userId: selectedUser.id,
          },
        },
      })
        .then(() => {
          onClose();
          onSuccess();
        })
        .catch(() => onError());
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Typography variant='h3' textAlign='center'>
          Добавить пользователя в курс
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Autocomplete<SelectedUser, false>
          value={selectedUser}
          onChange={(newValue) => setSelectedUser(newValue)}
          onInputChange={handleInputChange}
          loading={loading}
          options={userList || []}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Отменить</Button>
        <LoadingButton onClick={onAddUserToCourse} loading={loadingMutation} loadingIndicator='Загрузка'>
          Добавить
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserToCourseModal;
