import { CSSObject, Link, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import * as React from 'react';
import { useContext } from 'react';
import { AuthContext } from 'context/authContext';
import { styled, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import routes from 'layouts/MainLayout/routes';

const DRAWER_WIDTH = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  top: 'auto',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  top: 'auto',
});

// @ts-ignore
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open
    ? {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }
    : {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
})) as typeof MuiDrawer;

const DrawerMenu = () => {
  const { me, error } = useContext(AuthContext);
  const menuRoutes = routes();
  const { pathname } = useLocation();

  if (me && !error) {
    return (
      <Drawer variant='permanent' open>
        <List sx={{ pt: 3, '& a': { textDecoration: 'none' } }}>
          {menuRoutes.map((item) => {
            const Icon = item.icon;
            return (
              <Link to={item.href} key={item.href} component={RouterLink}>
                <ListItem
                  sx={
                    pathname.includes(item.href)
                      ? {
                          bgcolor: 'base.200',
                          '& > div *': {
                            fontWeight: 500,
                          },
                        }
                      : undefined
                  }
                >
                  <ListItemIcon sx={{ minWidth: 45 }}>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Link>
            );
          })}
        </List>
      </Drawer>
    );
  }
  return null;
};

export default DrawerMenu;
