import React, { FC } from 'react';
import { Box, BoxProps, Grid } from '@mui/material';
import TextFieldControl from 'components/inputs/control/TextFieldControl';
import { UseFormReturn } from 'react-hook-form';
import FileItem from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/elements/QuestionContainer/FileItem';
import AttachFile from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/elements/QuestionContainer/AttachFile';
import {
  IFewAnswerProps,
  FewAnswer,
  SingleAnswer,
  TextAnswer,
  Matching,
  ISingleAnswerProps,
  ITextAnswerProp,
  IMatchingProps,
} from '../answers';
import { ExerciseForm, ExtendedElementTypes, QuestionComponents } from '../../types';
import ScoreSettings from './ScoreSettings';

type QuestionProps = {
  elementIndex: number;
  disabled?: boolean;
  itemType: QuestionComponents;
  form: UseFormReturn<ExerciseForm>;
};

type ComponentType = {
  [ExtendedElementTypes.QUESTION_FEW_ANSWERS]: FC<IFewAnswerProps>;
  [ExtendedElementTypes.QUESTION_MATCHING]: FC<IMatchingProps>;
  [ExtendedElementTypes.QUESTION_TEXT_ANSWERS]: FC<ITextAnswerProp>;
  [ExtendedElementTypes.QUESTION_SINGLE_ANSWER]: FC<ISingleAnswerProps>;
  [ExtendedElementTypes.QUESTION_FILE_ANSWER]: FC<BoxProps>;
};

const COMPONENT: ComponentType = {
  [ExtendedElementTypes.QUESTION_FEW_ANSWERS]: FewAnswer,
  [ExtendedElementTypes.QUESTION_MATCHING]: Matching,
  [ExtendedElementTypes.QUESTION_TEXT_ANSWERS]: TextAnswer,
  [ExtendedElementTypes.QUESTION_SINGLE_ANSWER]: SingleAnswer,
  [ExtendedElementTypes.QUESTION_FILE_ANSWER]: Box,
};

const QuestionContainer: FC<QuestionProps> = ({ elementIndex, disabled, itemType, form }) => {
  const Component = itemType ? COMPONENT[itemType] : Box;
  const { watch } = form;
  const watchFiles = watch(`elements.${elementIndex}.files`) || [];

  return (
    <Box flexGrow={1}>
      <Grid container spacing={2}>
        <Grid container spacing={2} item xs={12}>
          <Grid container item xs={12} md={8}>
            <Grid item xs={12}>
              <TextFieldControl
                minRows={4}
                multiline
                label='Описание'
                name={`elements.${elementIndex}.description`}
                rules={{
                  validate: (value, formValues) => !!formValues.elements[elementIndex].title || !!value,
                }}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <Component {...{ elementIndex, itemType, form }} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <ScoreSettings elementIndex={elementIndex} elementType={itemType} form={form} />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid container item spacing={3}>
            {watchFiles?.map((currentFile, index) => (
              <FileItem
                currentFile={currentFile}
                elementIndex={elementIndex}
                fileIndex={index}
                form={form}
                disabled={disabled}
              />
            ))}
          </Grid>
          <Grid item>
            <AttachFile form={form} disabled={disabled} elementIndex={elementIndex} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default QuestionContainer;
