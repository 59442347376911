import React, { FC, ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

type PhotoUploadModalProps = {
  open: boolean;
  onClose: () => void;
  content: ReactNode;
  onAction: () => void;
};

const PhotoUploadModal: FC<PhotoUploadModalProps> = ({ open, onClose, content, onAction }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle id='alert-dialog-title' typography='h4'>
      Загрузка фото
    </DialogTitle>
    {content && <DialogContent>{content}</DialogContent>}
    <DialogActions>
      <Button onClick={onClose}>Отменить</Button>
      <Button onClick={onAction}>Сохранить</Button>
    </DialogActions>
  </Dialog>
);

export default PhotoUploadModal;
