import generateOptions from 'helpers/array';
import { ExtendedElementTypes } from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/types';

type IElementName = {
  [key in Exclude<ExtendedElementTypes, ExtendedElementTypes.FEEDBACK>]: string;
};

export const ELEMENT_NAME: IElementName = {
  // FEEDBACK: 'Обратная связь с реакцией',
  FILE: 'Скачивание файлов',
  HEADING: 'Заголовок',
  TEXT: 'Текст',
  VIDEO: 'Видео',
  QUESTION_MATCHING: 'Вопрос на соответствие',
  QUESTION_SINGLE_ANSWER: 'Вопрос с одним ответом',
  QUESTION_FEW_ANSWERS: 'Вопрос с несколькими ответами',
  QUESTION_TEXT_ANSWERS: 'Вопрос с открытым ответом',
  QUESTION_FILE_ANSWER: 'Загрузка файла',
  EXERCISE_LINK: 'Домашнее задание',
};
export const ELEMENT_OPTIONS = generateOptions(ELEMENT_NAME);
