import { formatISODate } from 'helpers/date';
import { HOMEWORK_STATUS_COLOR, HOMEWORK_STATUS_NAME, SUBJECT_NAME } from 'constants/global';
import { AdminUserAnswerListOrderFields, UserAnswerStatus, UsersAnswersQuery } from '@generated/graphql';
import { Chip, IconButton } from '@mui/material';
import { createUserName } from 'helpers/common';
import React from 'react';
import { Link } from 'react-router-dom';
import { Checklist } from '@mui/icons-material';

type Homework = NonNullable<NonNullable<UsersAnswersQuery['usersAnswers']>['data']>[number];

const HOMEWORK_TABLE = [
  {
    title: 'ID',
    value: (homework: Homework) => homework.user?.numericId || '—',
    loadingValue: '1355',
  },
  {
    title: 'ФИО',
    value: ({
      user,
    }: {
      user: {
        firstName: string;
        lastName: string;
        middleName: string;
      };
    }) => createUserName(user),
    loadingValue: 'Иванов Иван Иваныч',
  },
  {
    title: 'Предмет',
    value: ({
      element: {
        exercise: {
          lesson: {
            block: {
              course: { subject },
            },
          },
        },
      },
    }: {
      element: Homework['element'];
    }) => (subject ? SUBJECT_NAME[subject] : '—'),
    loadingValue: '100',
  },
  {
    title: 'Класс',
    value: ({
      element: {
        exercise: {
          lesson: {
            block: {
              course: { grade },
            },
          },
        },
      },
    }: {
      element: Homework['element'];
    }) => grade || '—',
    loadingValue: '100',
  },
  {
    title: 'Дата изменения',
    value: (homework: any) => (homework.updatedAt ? formatISODate(homework.updatedAt, 'dd.MM.yyyy HH:mm') : '—'),
    loadingValue: '100',
    sortName: AdminUserAnswerListOrderFields.UpdatedAt,
  },
  {
    title: 'Статус',
    value: ({ status }: { status: UserAnswerStatus }) => (
      <Chip
        label={HOMEWORK_STATUS_NAME[status]}
        sx={{ backgroundColor: HOMEWORK_STATUS_COLOR[status] }}
        variant='outlined'
      />
    ),
    loadingValue: 'ХXXXXXXXXX',
  },
  {
    title: '',
    value: (homework: Homework) => (
      <IconButton
        size='large'
        component={Link}
        onClick={(e) => e.stopPropagation()}
        to={`${homework.element.exercise.lesson.block.course.id}/${homework.user.id}`}
        rel='noopener noreferrer'
      >
        <Checklist />
      </IconButton>
    ),
    tooltip: () => 'Список всех ДЗ пользователя',
    loadingValue: 'Х',
  },
];

export default HOMEWORK_TABLE;
