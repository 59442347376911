import React, { FC } from 'react';
import { Card, Stack } from '@mui/material';
import TextFieldControl from 'components/inputs/control/TextFieldControl';
import CheckboxControl from 'components/inputs/control/CheckboxControl';
import AutocompleteControl from 'components/inputs/control/AutocompleteControl';
import { EXERCISE_TYPE_OPTIONS } from 'constants/global';
import { UseFormReturn } from 'react-hook-form';

type LessonProps = {
  disabled?: boolean;
  parentName: string;
  form: UseFormReturn;
};

const Exercise: FC<LessonProps> = ({ parentName, disabled, form }) => {
  const { watch } = form;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isTimeLimit = watch(`${parentName}.isTimeLimit`);

  return (
    <Card sx={{ width: '100%' }}>
      <Stack spacing={3} sx={{ flexGrow: 1 }}>
        <Stack spacing={4} direction='row' justifyContent='space-between'>
          <TextFieldControl label='Название' name={`${parentName}.title`} disabled={disabled} sx={{ width: '50%' }} />
          {/* <Stack direction='row' width='40%' justifyContent='flex-end'> */}
          {/*  <CheckboxControl disabled={disabled} label='Время прохождения' name={`${parentName}.isTimeLimit`} /> */}
          {/*  <TextFieldControl */}
          {/*    name={`${parentName}.timeLimit`} */}
          {/*    disabled={disabled || !isTimeLimit} */}
          {/*    maskType='fullTime' */}
          {/*    sx={{ maxWidth: 100 }} */}
          {/*  /> */}
          {/* </Stack> */}
        </Stack>

        <AutocompleteControl name={`${parentName}.type`} options={EXERCISE_TYPE_OPTIONS} label='Тип' />
        <CheckboxControl disabled={disabled} label='Учитывать в прогрессе' name={`${parentName}.inProgress`} />
      </Stack>
    </Card>
  );
};

export default Exercise;
