import { Stack, Typography, Link } from '@mui/material';
import { UserAnswerQuery } from '@generated/graphql';
import ROUTES from 'constants/routes';

interface IHomeworkInfoProps {
  userAnswer: UserAnswerQuery['userAnswer'];
}

const HomeworkInfo = ({ userAnswer }: IHomeworkInfoProps) => {
  const lesson = userAnswer?.element.exercise.lesson;
  const lessonTitle = lesson?.title;
  const blockTitle = lesson?.block.title;
  const course = lesson?.block.course;
  const courseTitle = course?.title;
  return (
    <Stack spacing={1} flex={2}>
      <Typography variant='h6'>
        Курс:
        {'\u00A0'}
        <Link href={`/${ROUTES.COURSES}/${course?.id}`}>{courseTitle}</Link>
      </Typography>
      <Typography variant='h6'>{`Блок: ${blockTitle}`}</Typography>
      <Typography variant='h6'>{`Урок: ${lessonTitle}`}</Typography>
    </Stack>
  );
};

export default HomeworkInfo;
