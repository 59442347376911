import React, { FC } from 'react';
import { Stack } from '@mui/material';
import TextFieldControl from 'components/inputs/control/TextFieldControl';

type HeadingProp = {
  elementIndex: number;
  disabled?: boolean;
};
const Heading: FC<HeadingProp> = ({ elementIndex, disabled }) => (
  <Stack sx={{ mt: 3 }}>
    <TextFieldControl
      label='Текст заголовка'
      name={`elements.[${elementIndex}].title`}
      rules={{ required: true }}
      disabled={disabled}
    />
  </Stack>
);

export default Heading;
