import { useController } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  RadioProps,
  RadioGroup as MuiRadioGroup,
  Radio,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';

export interface IRadioButtonProps {
  value: any;
  label: string;
}

type IProps = {
  name: string;
  itemList: IRadioButtonProps[];
};

const RadioGroup: FC<RadioProps & IProps> = ({ name, itemList }) => {
  const { field } = useController({
    defaultValue: '',
    name,
  });
  return (
    <FormControl>
      <MuiRadioGroup name={name} value={field.value} onChange={field.onChange}>
        {itemList?.map((item: IRadioButtonProps) => {
          const { label, value } = item;
          return (
            <FormControlLabel
              key={`${name}.${value}`}
              value={value}
              control={<Radio />}
              label={<Typography dangerouslySetInnerHTML={{ __html: `${label || ''}` }} />}
            />
          );
        })}
      </MuiRadioGroup>
    </FormControl>
  );
};

export default RadioGroup;
