import { InputTypes } from 'components/generators/inputsGenerator';
import { FILTER_TYPES } from 'components/generators/filtersGenerator';
import { USER_COURSE_TYPE_OPTIONS } from 'constants/global';

const filterFields = () => [
  {
    name: 'courseNumericId',
    label: 'ID курса',
    inputType: InputTypes.TEXT,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    customFilter: (value: string) => parseFloat(value),
  },
  {
    name: 'courseTitle',
    label: 'Название курса',
    inputType: InputTypes.TEXT,
  },
  {
    name: 'type',
    label: 'Тип доступа',
    inputType: InputTypes.AUTOCOMPLETE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    options: USER_COURSE_TYPE_OPTIONS,
    customFilter: (value: any) => value.id,
  },
  {
    name: 'createdAt',
    label: 'Дата приобретения',
    inputType: InputTypes.DATE,
    filterType: FILTER_TYPES.CUSTOM_FILTER,
    customFilter: (value: string) => value,
  },
];

export default filterFields;
