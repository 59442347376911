import { createTheme } from '@mui/material';
import typography from './typography';

import colors from './colors';
import components from './components';

const createAppTheme = () =>
  createTheme({
    palette: colors,
    typography,
    components,
  });
export default createAppTheme;
