import React, { createContext, ReactNode, useMemo } from 'react';
import { MeQuery, useMeQuery } from '@generated/graphql';
import { ApolloError } from '@apollo/client';

interface IAuthContext {
  me: MeQuery['me'] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: any;
}

export const AuthContext = createContext<IAuthContext>({
  me: undefined,
  loading: false,
  error: undefined,
  refetch: () => {},
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { data, loading, error, refetch } = useMeQuery({ fetchPolicy: 'network-only' });
  const me = data?.me;
  const providerValue = useMemo(() => ({ me, loading, error, refetch }), [me, loading, error, refetch]);

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};
