import React, { FC, useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ButtonsWrapper, { ButtonVariantEnum } from 'components/buttons/ButtonWrapper';
import AutocompleteControl from 'components/inputs/control/AutocompleteControl';
import { UseFieldArrayReturn, UseFormReturn, useWatch } from 'react-hook-form';
import { ELEMENT_OPTIONS } from 'constants/course';
import TextFieldControl from 'components/inputs/control/TextFieldControl';
import { ElementUnionType, ExerciseForm, ExtendedElementTypes } from './types';
import ExerciseLink from './elements/ExerciseLink';
import { Heading, Feedback, Files, Text, Video, Question } from './elements';

type ElementProps = {
  form: UseFormReturn<ExerciseForm>;
  elementIndex: number;
  element: any;
  fieldArray: UseFieldArrayReturn;
  isUploadFileElementExist: boolean;
};

type ComponentType = {
  [key in ExtendedElementTypes]: FC<any>;
};

const COMPONENT: ComponentType = {
  [ExtendedElementTypes.FEEDBACK]: Feedback,
  [ExtendedElementTypes.FILE]: Files,
  [ExtendedElementTypes.TEXT]: Text,
  [ExtendedElementTypes.VIDEO]: Video,
  [ExtendedElementTypes.HEADING]: Heading,
  [ExtendedElementTypes.QUESTION_FILE_ANSWER]: Question,
  [ExtendedElementTypes.QUESTION_MATCHING]: Question,
  [ExtendedElementTypes.QUESTION_SINGLE_ANSWER]: Question,
  [ExtendedElementTypes.QUESTION_FEW_ANSWERS]: Question,
  [ExtendedElementTypes.QUESTION_TEXT_ANSWERS]: Question,
  [ExtendedElementTypes.EXERCISE_LINK]: ExerciseLink,
};

type FieldName = `elements.${number}`;

const Element: FC<ElementProps> = ({ form, elementIndex, element, fieldArray, isUploadFileElementExist }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = form;
  const elementName: FieldName = `elements.${elementIndex}`;

  const item = useWatch({
    control,
    name: elementName,
  }) as ElementUnionType;

  const itemType = item?.type?.id;
  const isQuestion = itemType?.includes('QUESTION');
  const Component = itemType ? COMPONENT[itemType] : null;

  const isOpen = item?.open;
  const handleOpenAccordion = () => setValue(`${elementName}.open`, !isOpen);

  const elementError = !!errors?.elements?.[elementIndex];

  const filteredElementOptions = useMemo(
    () =>
      isUploadFileElementExist
        ? ELEMENT_OPTIONS.filter((option) => option.id !== 'QUESTION_FILE_ANSWER')
        : ELEMENT_OPTIONS,
    [isUploadFileElementExist],
  );

  return (
    <ButtonsWrapper
      key={element.formId}
      data={element}
      fieldArray={fieldArray}
      index={elementIndex}
      variant={ButtonVariantEnum.ELEMENT}
    >
      <Accordion
        component='div'
        sx={{
          flexGrow: 1,
          maxWidth: 'calc(100% - 30px)',
          px: 1,
          py: 2,
          borderBottom: (theme) =>
            `${isOpen ? '3px ' : '1px '}solid ${isOpen ? theme.palette.primary.main : theme.palette.divider}`,
          border: (theme) => `1px solid ${elementError ? theme.palette.error.main : theme.palette.divider}`,
          backgroundColor: 'white',
          '& .Mui-focusVisible': { backgroundColor: 'transparent !important' },
        }}
        expanded={isOpen}
        onChange={handleOpenAccordion}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack spacing={2} sx={{ width: '98%' }} onClick={(e) => e.stopPropagation()}>
            <Stack spacing={1} direction='row' sx={{ width: '100%' }} alignItems='center'>
              <Typography>{`${elementIndex + 1}.`}</Typography>
              <AutocompleteControl
                name={`${elementName}.type`}
                options={filteredElementOptions}
                label='Тип'
                rules={{ required: true }}
                fullWidth
              />
            </Stack>
            {isQuestion && (
              <TextFieldControl
                sx={{ mb: 3 }}
                label='Заголовок'
                name={`${elementName}.title`}
                rules={{
                  validate: (value, formValues) => !!formValues.elements[elementIndex].description || !!value,
                }}
              />
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {Component && isOpen && (
            <Stack spacing={3} sx={{ flexGrow: 1 }}>
              <Component form={form} elementIndex={elementIndex} itemType={itemType} />
            </Stack>
          )}
        </AccordionDetails>
      </Accordion>
    </ButtonsWrapper>
  );
};

export default Element;
