import { FormControl, FormControlLabel, Radio, RadioGroup as MuiRadioGroup, Stack, Typography } from '@mui/material';
import { useController, UseFormReturn } from 'react-hook-form';
import React, { FC } from 'react';
import TextFieldControl from 'components/inputs/control/TextFieldControl';
import ActionButton, { ActionButtonEnum } from 'components/buttons/ActionButton';
import InsertButton from 'components/buttons/InsertButton';
import { ExerciseForm } from '../../../types';

type AnswerProps = {
  parentName: `elements.${number}`;
  itemList: any[];
  removeRadio: (index: number) => any;
  form: UseFormReturn<ExerciseForm>;
};

const Answer: FC<AnswerProps> = ({ parentName, itemList, removeRadio, form }) => {
  const { field } = useController({
    defaultValue: '',
    name: `${parentName}.singleCorrectAnswer`,
  });

  return (
    <FormControl>
      <MuiRadioGroup value={field.value} onChange={field.onChange}>
        <Stack spacing={2}>
          {itemList?.map((item: any, index) => {
            const { label, formId } = item;
            return (
              <Stack spacing={3} direction='row' alignItems='center' key={`single-answer-${formId}`}>
                <Typography>{`${index + 1})`}</Typography>
                <FormControlLabel
                  value={index}
                  control={<Radio />}
                  label={<Typography dangerouslySetInnerHTML={{ __html: `${label || ''}` }} />}
                />
                <InsertButton<ExerciseForm> form={form} inputName={`${parentName}.elementParts.${index}.content`}>
                  <TextFieldControl
                    sx={{ mr: 2 }}
                    placeholder='Ответ'
                    name={`${parentName}.elementParts[${index}].content`}
                    rules={{ required: true }}
                    multiline
                  />
                </InsertButton>
                <ActionButton
                  handleClick={() => removeRadio(index)}
                  type={ActionButtonEnum.DELETE}
                  sx={{ ml: '24px !important' }}
                />
              </Stack>
            );
          })}
        </Stack>
      </MuiRadioGroup>
    </FormControl>
  );
};

export default Answer;
