import { Button } from '@mui/material';
import MenuButton from 'components/buttons/MenuButton';
import { FC, useContext, useState } from 'react';
import { ModalContext } from 'context/modalContext';
import { GetCourseHeaderQuery, useDeleteCourseMutation, usePublishOrHideCourseMutation } from '@generated/graphql';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import CopyCourseModal from 'pages/Course/view/components/CopyCourseModal';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'constants/routes';

interface HeaderButtonsProps {
  course: GetCourseHeaderQuery['course'];
}

const HeaderButtons: FC<HeaderButtonsProps> = ({ course }) => {
  const { handleOpen } = useContext(ModalContext);
  const { addToast } = useContext(ToastContext);
  const navigate = useNavigate();

  const [openCopyCourseModal, setOpenCopyCourseModal] = useState<boolean>(false);

  const [publishOrHideCourse] = usePublishOrHideCourseMutation();
  const [deleteCourse] = useDeleteCourseMutation();

  const { isHidden } = course;

  const onError = (text?: string) => addToast({ type: ToastTypeEnum.ERROR, text });
  const onSuccess = (text?: string) => () => addToast({ type: ToastTypeEnum.SUCCESS, text });

  const onPublishOrHide = () =>
    handleOpen({
      title: isHidden ? 'Опубликовать курс' : 'Скрыть курс',
      content: isHidden
        ? `Вы уверены, что хотите опубликовать  ${course?.title ? `курс «${course?.title}»` : 'выбранное курс'}?`
        : `Вы уверены, что хотите скрыть курс «${course?.title}»?`,
      buttonText: isHidden ? 'Опубликовать' : 'Скрыть',
      handleClick: async () =>
        publishOrHideCourse({ variables: { courseId: course.id } })
          .then(() => onSuccess())
          .catch(() => onError()),
    });

  const onDelete = () =>
    handleOpen({
      title: 'Удалить курс',
      content: `Вы уверены, что хотите удалить курс
                       «${course?.title}»? Отменить это действие будет невозможно!`,
      handleClick: async () =>
        deleteCourse({
          variables: { courseId: course.id },
        })
          .then((res) => {
            if (res.data?.deleteCourse) {
              navigate(`/${ROUTES.COURSES}`);
              onSuccess('Курс успешно удален');
            } else onError();
          })
          .catch(() => onError()),
    });

  const toggleOpenCopyCourseModal = () => setOpenCopyCourseModal(true);
  const toggleCloseCopyCourseModal = () => setOpenCopyCourseModal(false);

  return (
    <>
      <Button size='small' variant='contained'>
        Предпросмотр
      </Button>
      <MenuButton>
        <Button onClick={toggleOpenCopyCourseModal}>Копировать курс</Button>
        <Button onClick={onPublishOrHide}>{isHidden ? 'Опубликовать' : 'Скрыть'}</Button>
        <Button onClick={onDelete} sx={{ color: 'error.main' }}>
          Удалить
        </Button>
      </MenuButton>
      <CopyCourseModal onClose={toggleCloseCopyCourseModal} open={openCopyCourseModal} course={course} />
    </>
  );
};

export default HeaderButtons;
