import { ElementFileType } from '@generated/graphql';

export const DEFAULT_FILE_STRUCTURE = {
  type: ElementFileType.Cover,
  id: null,
  fileName: null,
  file: null,
};

export const DEFAULT_FILE_PAIR = {
  cover: { ...DEFAULT_FILE_STRUCTURE },
  download: {
    ...DEFAULT_FILE_STRUCTURE,
    type: ElementFileType.Download,
  },
};
