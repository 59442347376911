import { INPUT_DEFAULT_VALUE, InputTypes } from 'components/generators/inputsGenerator';
// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'qs';
import { addDays, format, isValid } from 'date-fns';
import _ from 'lodash';

export const FILTER_TYPES = {
  STRING_FILTER: 'StringFilter',
  BOOL_FILTER: 'BoolFilter',
  MULTIPLE_STRING: 'MultipleString',
  ENUM_FILTER: 'EnumFilter',
  CUSTOM_FILTER: 'CustomFilter',
  DATE_FILTER: 'DateFilter',
  NUMBER_FILTER: 'NumberFilter',
};

export const generateQueryOnSubmit = (data: any, filters: any, setSearchParams: any) => {
  const params = {};
  filters.forEach((filter: any) => {
    const { inputType, name } = filter;
    const dataName = _.get(data, name);
    const setParam = (newData: any) => _.set(params, name, newData);
    if (!dataName) return;
    switch (inputType) {
      case InputTypes.DATE: {
        if (isValid(dataName)) setParam(format(dataName, 'yyyy-MM-dd'));
        else setParam(dataName);
        break;
      }
      default: {
        setParam(dataName);
        break;
      }
    }
  });
  setSearchParams(qs.stringify(params));
};
// юзаем чтобы очистить инпуты и router.query
export const onClearFilter = (filters: any, reset: any, setSearchParams: any) => {
  setSearchParams({});
  const params = {};
  filters.forEach((filter: any) => {
    const { name, defaultValue, multiple } = filter;
    const inputType = filter.inputType as InputTypes;
    _.set(params, name, multiple ? [] : defaultValue || INPUT_DEFAULT_VALUE[inputType]);
    // if (filter.refetch) filter.refetch({page: 0, limit: 10, filter: {}});
  });
  reset(params);
};

// это вставляем в useEffect для заполнения инпутов данными из query
export const onUseEffect = (filters: any, reset: any, query: any) => {
  const params = {};
  filters.forEach((filter: any) => {
    const { name } = filter;
    const inputType = filter.inputType as InputTypes;

    if (_.get(query, filter.name)) {
      const dataName = _.get(query, name);
      const setParam = (newData: any) => _.set(params, name, newData);
      setParam(dataName || INPUT_DEFAULT_VALUE[inputType]);
    } else _.set(params, name, INPUT_DEFAULT_VALUE[inputType]);
  });
  if (Object.keys(params).length) {
    reset(params);
  }
};

// это вставляем в запрос по типу {clientFilters: generateFilterQuery(...)}
export const generateFilterQuery = (filters: any, query: any) => {
  const params: any = {};
  filters.forEach((filter: any) => {
    if (_.get(query, filter.name)) {
      const filterName = filter.filterName || filter.name;
      const queryValue = _.get(query, filter.name);
      const setParam = (newData: any) => _.set(params, filterName, newData);
      const searchField = filter.searchField || 'id';
      switch (filter.filterType) {
        case FILTER_TYPES.STRING_FILTER: {
          setParam({ contains: queryValue });
          break;
        }
        case FILTER_TYPES.NUMBER_FILTER: {
          setParam({ equalsTo: Number(queryValue) });
          break;
        }
        case FILTER_TYPES.DATE_FILTER: {
          params.AND = [
            _.set({}, filterName, { moreThanOrEqual: format(queryValue, 'yyyy-mm-dd') }),
            _.set({}, filterName, { lessThan: addDays(queryValue, 1) }),
          ];
          break;
        }
        case FILTER_TYPES.BOOL_FILTER: {
          setParam({ value: Boolean(Number(queryValue[searchField])) });
          break;
        }
        case FILTER_TYPES.ENUM_FILTER: {
          setParam({ values: queryValue[searchField] });
          break;
        }
        case FILTER_TYPES.MULTIPLE_STRING: {
          setParam({ values: queryValue.map((obj: any) => obj[searchField]) });
          break;
        }
        case FILTER_TYPES.CUSTOM_FILTER: {
          setParam(filter.customFilter(queryValue));
          break;
        }
        default:
          setParam(queryValue);
          break;
      }
    }
  });

  return Object.keys(params).length === 0 ? undefined : params;
};
