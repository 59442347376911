import { ElementFileType, ExerciseType, QuestionScoreType, QuestionType } from '@generated/graphql';
import { UseFieldArrayReturn } from 'react-hook-form';

export enum ExtendedElementTypes {
  FEEDBACK = 'FEEDBACK',
  FILE = 'FILE',
  HEADING = 'HEADING',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
  QUESTION_MATCHING = 'QUESTION_MATCHING',
  QUESTION_SINGLE_ANSWER = 'QUESTION_SINGLE_ANSWER',
  QUESTION_FEW_ANSWERS = 'QUESTION_FEW_ANSWERS',
  QUESTION_TEXT_ANSWERS = 'QUESTION_TEXT_ANSWERS',
  QUESTION_FILE_ANSWER = 'QUESTION_FILE_ANSWER',
  EXERCISE_LINK = 'EXERCISE_LINK',
}

interface ICommonElementField {
  id?: string;
  type: { id: ExtendedElementTypes; displayName: string };
  open: boolean;
}

export interface ITextElement extends ICommonElementField {
  __typename?: 'TextElement';
  content: string;
}

export interface IVideoElement extends ICommonElementField {
  __typename?: 'VideoElement';
  kinescopeId: string;
}

export interface IFile {
  id?: string | null;
  type?: ElementFileType;
  fileName?: string | null;
  fileNameWithoutExtension?: string;
  file?: {
    __typename?: 'File';
    id?: string;
    name: string;
    url?: string;
    preview?: string;
  } | null;
}

export interface FilePair {
  cover: IFile;
  download: IFile;
}

export interface IFileElement extends ICommonElementField {
  __typename?: 'FileElement';
  filePairs?: FilePair[] | null;
}

export interface IHeadingElement extends ICommonElementField {
  typename?: 'HeadingElement';
  title?: string | null;
}

export interface ElementPart {
  __typename?: 'ElementPart';
  id?: string;
  correctAnswer?: string | null;
  isCorrect?: boolean | null;
  content?: string | null;
}

export interface ICommonQuestionElement extends ICommonElementField {
  __typename?: 'QuestionElement';
  questionType: QuestionType;
  title?: string | null;
  description: string;
  placeholder?: string | null;
  elementParts?: Array<ElementPart> | null;
  files?: Array<IFile> | null;
}

export interface IQuestionFullScore extends ICommonQuestionElement {
  maxScore: string;
  scoreType: QuestionScoreType.Full;
}

export interface IQuestionPartialScore extends ICommonQuestionElement {
  negativeScore: string;
  positiveScore: string;
  scoreType: QuestionScoreType.Partial;
}

export interface IQuestionSingleAnswer extends ICommonQuestionElement {
  questionType: QuestionType.SingleAnswer;
  singleCorrectAnswer: string;
}

export interface IQuestionMultipleAnswer extends ICommonQuestionElement {
  questionType: QuestionType.FewAnswers;
  isCorrect: boolean;
}

export type IQuestionElement =
  | (IQuestionFullScore & IQuestionSingleAnswer)
  | (IQuestionFullScore & IQuestionMultipleAnswer)
  | (IQuestionPartialScore & IQuestionSingleAnswer)
  | (IQuestionPartialScore & IQuestionMultipleAnswer)
  | (IQuestionPartialScore & ICommonQuestionElement)
  | (IQuestionFullScore & ICommonQuestionElement);

export type ElementUnionType = IFileElement | IHeadingElement | IQuestionElement | IVideoElement | ITextElement;

export type ExerciseForm = {
  id?: string | undefined;
  title?: string | undefined;
  type?: ExerciseType | undefined;
  elements: ElementUnionType[] | undefined;
};

export type QuestionComponents =
  | ExtendedElementTypes.QUESTION_FEW_ANSWERS
  | ExtendedElementTypes.QUESTION_MATCHING
  | ExtendedElementTypes.QUESTION_TEXT_ANSWERS
  | ExtendedElementTypes.QUESTION_FILE_ANSWER
  | ExtendedElementTypes.QUESTION_SINGLE_ANSWER;

export type FilePairFieldArrayType = UseFieldArrayReturn<ExerciseForm, `elements.${number}.filePairs`, 'formId'>;

export type FilesFieldName = `elements.${number}.filePairs`;

export type CurrentFileFieldName = `${FilesFieldName}.${number}`;

export type CoverFileUrlFieldName = `${CurrentFileFieldName}.file.url`;
export type CoverFilePreviewFieldName = `${CurrentFileFieldName}.file.preview`;
